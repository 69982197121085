<template>
  <div id="withdrawalMethod">
    <div>
      <el-row :gutter="40" justify="space-between" type="flex">
        <el-col :xs="16" :sm="12">
          <div class="title">{{ $t('common.withdrawChannel.creditcard') }}</div>
        </el-col>
        <el-col :xs="8" :sm="12" class="totalAmount"> {{ $t('common.field.amt') }}: {{ amount }} </el-col>
      </el-row>
      <el-row>
        <el-col :xs="24">
          <div class="desc">1. {{ $t('withdraw.ccMethod.desc1') }}</div>
          <div class="desc">
            2.
            <i18n path="withdraw.ccMethod.desc2">
              <template v-slot:mailto>
                <a :href="'mailto:' + GLOBAL_CONTACT_EMAIL" class="red"> {{ GLOBAL_CONTACT_EMAIL }}</a>
              </template>
            </i18n>
          </div>
        </el-col>
      </el-row>
      <CcTab
        ref="creditCardTab"
        :creditCardList="creditCardList"
        @updateCcForm="updateCcForm"
        :amount="amount"
        :form="form"
        :disableField="disableField"
      />
    </div>
    <div class="expired-cc" v-if="hasUnavailableCard">
      <div class="header">
        <div class="title">{{ $t('common.withdrawChannel.expiredCard') }} ({{ expriredCount }})</div>
        <span class="hide-cc" @click="hideExpiredCreditcard = !hideExpiredCreditcard">
          {{ hideExpiredCreditcard ? $t('common.button.showAll') : $t('common.button.hide') }}
        </span>
      </div>
      <div v-show="!hideExpiredCreditcard">
        <CcTab
          ref="creditCardUnavailableTab"
          :creditCardList="creditCardList"
          @updateCcForm="updateCcForm"
          :amount="amount"
          :form="form"
          :showAvailable="false"
        />
      </div>
    </div>
  </div>
</template>

<script>
import rounding from '@/util/rounding';
import CreditCardWithdraw from '@/components/withdraw/CreditCardWithdraw';
import CcTab from './CcTab.vue';
import { decimalAdding } from '@/util/decimal';

export default {
  name: 'ccMethod',
  components: { CreditCardWithdraw, CcTab },
  props: { withdrawForm: Object, topForm: Object },
  data() {
    return {
      form: {},
      creditCardList: [],
      unavailableCardList: [],
      allCcComponent: [],
      hasUnavailableCard: false,
      hideExpiredCreditcard: false
    };
  },
  mounted() {
    this.creditCardList = JSON.parse(JSON.stringify(this.topForm.creditCardBalances));
    this.creditCardList = this.creditCardList.map(m => {
      const cc = m.creditCard;
      const archived = cc.is_del === 1;
      const expired = m.isExpired;
      const refunded = m.balance <= 0;
      const pending = m.isPending;
      const available = !archived && !expired && !refunded && !pending;
      m.availableCard = available;
      if (!available) {
        this.hasUnavailableCard = true;
      }
      return m;
    });
  },
  watch: {
    creditCardList() {
      let balance = 0;
      let amount = this.amount;
      let form = {};
      let count = this.creditCardList.length;

      this.creditCardList.forEach((m, i) => {
        m.showBalance = m.balance;
        form[`form_${i}`] = i;
        m.selectedCardList = [...JSON.parse(JSON.stringify(this.creditCardList)).slice(i, count)];
        if (balance < this.amount) {
          console.log(m.showBalance, m.availableCard, m.creditCard.card_last_four_digits, this.amount);
          m.isShow = true;

          if (m.availableCard) {
            balance = decimalAdding(balance, m.showBalance, 2);
            balance > this.amount ? (m.showBalance = amount) : (amount = decimalAdding(amount, -m.showBalance, 2));
          }
        } else {
          m.isShow = false;
        }
      });
      console.log('this.creditCardList', this.creditCardList);
      this.form = form;
    }
  },
  computed: {
    amount() {
      return this.topForm.totalCcBalance >= this.withdrawForm.amount
        ? this.withdrawForm.amount
        : this.topForm.totalCcBalance;
    },
    disableField() {
      return this.amount < this.withdrawForm.amount;
    },
    expriredCount() {
      let count = 0;
      this.creditCardList.forEach(creditCard => {
        if (!creditCard.availableCard) {
          count++;
        }
      });
      return count;
    }
  },
  methods: {
    roundingNumber(number) {
      return parseFloat(rounding(Math.ceil, number, 2));
    },
    updateCcForm(index) {
      let copyArr = [...this.creditCardList];
      copyArr = this.swapArray(copyArr, index, this.form[`form_${index}`]);
      this.creditCardList = [...copyArr];
    },
    swapArray(array, indexA, indexB) {
      let temp = array[indexA];
      array[indexA] = array[indexB];
      array[indexB] = temp;
      return array;
    },
    getFormOfChild() {
      let getFormOfAvailableComponent = [];
      const refs = this.$refs.creditCardTab.$refs;
      this.allCcComponent.map(m => {
        if (refs[m].length > 0) {
          getFormOfAvailableComponent.push(refs[m][0].getFormData());
        }
      });

      return getFormOfAvailableComponent;
    },
    getFormDataOfChild() {
      return {
        qAccount: this.withdrawForm.account,
        amount: this.amount,
        withdrawalType: 1,
        creditCardWithdrawalRequests: [...this.getFormOfChild()]
      };
    },
    submitForm() {
      let availableComponent = [];

      const refs = this.$refs.creditCardTab.$refs;

      this.allCcComponent = Object.keys(refs);
      this.allCcComponent.map(m => {
        if (refs[m].length > 0) {
          const validForm = refs[m][0].$refs['form'].validate();
          this.openValidFailComponent(validForm, m);
          availableComponent.push(validForm);
        }
      });

      return availableComponent;
    },
    openValidFailComponent(validForm, refsName) {
      validForm.catch(c => (document.getElementById(`tab${refsName.split('creditCardWithdraw')[1]}`).checked = true));
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/components/withdraw/ccMethod.scss';
</style>
