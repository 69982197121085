<template>
  <div id="MexicoWithdraw" class="bankForm_wrapper">
    <el-form label-position="top" :model="form" ref="form" :rules="rules">
      <el-row :gutter="10">
        <el-col :xs="24" :sm="11" class="label_wrapper">
          <div class="label fl">{{ $t('common.withdrawChannel.banktransfer') }}</div>
          <el-form-item prop="selectedCardID" class="fr">
            <el-select v-model="form.selectedCardID" data-testid="selectedCardID">
              <el-option
                v-for="item in availableCards"
                :key="item.id"
                :value="item.id"
                :label="item.id | cardInfoDropdown(item, withdrawalType, dropdownTranslationObj)"
                :data-testid="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :xs="24" :sm="11" class="label_wrapper">
          <div class="label fl2 label_margin-left">{{ $t('common.field.amt') }}</div>
          <el-form-item prop="availableAmount" class="fr2">
            <el-input data-testid="availableAmount" v-model="amount" disabled></el-input> </el-form-item
        ></el-col>
      </el-row>
      <div v-if="form.selectedCardID || form.selectedCardID === 0">
        <el-row :gutter="20">
          <el-col :xs="24" :sm="8">
            <el-form-item :label="$t('common.field.bankName')" prop="bankName" required>
              <el-select v-model="form.bankName" data-testid="bankName" :disabled="isdisabled">
                <el-option
                  v-for="item in bankList"
                  :key="item.value"
                  :value="item.value"
                  :label="item.label"
                  :data-testid="item.label"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="8">
            <el-form-item :label="$t('common.field.bankBeneficiaryName')" prop="beneficiaryName" required>
              <el-input v-model="form.beneficiaryName" :disabled="isdisabled" data-testid="beneficiaryName"></el-input>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="8">
            <el-form-item :label="$t('common.field.bankAccNum')" prop="accountNumber">
              <el-input v-model.trim="form.accountNumber" :disabled="isdisabled" data-testid="accountNumber"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :xs="24" :sm="8">
            <el-form-item :label="$t('common.field.bankProvince')" prop="bankProvince">
              <el-input v-model="form.bankProvince" :disabled="isdisabled" data-testid="bankProvince"></el-input>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="8">
            <el-form-item :label="$t('common.field.bankCity')" prop="bankCity">
              <el-input v-model="form.bankCity" :disabled="isdisabled" data-testid="bankCity"></el-input>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row type="flex" class="row-bg" justify="space-between">
          <el-col :span="24" v-if="!isdisabled">
            <el-form-item :label="$t('withdraw.transfer.label.upload')" class="upload" prop="uploadedFile">
              <vUpload :limit="1" v-on:updateFileInfo="updateFileInfo" data-testid="uploadedFile"></vUpload>
            </el-form-item>
          </el-col>
        </el-row>

        <div class="checkbox_wrapper" v-if="!form.userPaymentInfoId">
          <el-checkbox v-model="isRememberInfo" data-testid="checkbox">
            {{ $t('withdraw.default.remember') }}</el-checkbox
          >
        </div>
      </div>
    </el-form>
  </div>
</template>

<script>
import withdrawlMixin from '@/mixins/withdrawl/withdrawl';
import bankTransferMixin from '@/mixins/withdrawl/bankTransfer';
import vUpload from '@/components/vUpload';

export default {
  name: 'MexicoWithdraw',
  components: { vUpload },
  props: {
    accountNumber: Number,
    amount: Number,
    withdrawalType: Number,
    currentCurrency: String,
    userCountryCode: Number,
    bankList: {
      type: Array,
      default: () => []
    }
  },
  mixins: [bankTransferMixin, withdrawlMixin],
  data() {
    const validateFile = (rule, value, callback) => {
      if (this.fileList.length < 1) callback(new Error(this.$t('withdraw.transfer.formValidation.upload')));
      else callback();
    };
    return {
      form: {
        bankName: '',
        beneficiaryName: '',
        accountNumber: '',
        bankProvince: '',
        bankCity: '',
        importantNotes: '',
        userPaymentInfoId: null
      },
      rules: {
        bankName: [
          {
            required: true,
            message: this.$t('common.formValidation.bankNameReq'),
            trigger: ['blur', 'change']
          }
        ],
        beneficiaryName: [
          {
            required: true,
            message: this.$t('common.formValidation.dynamicReq', {
              dynamic: this.$t('common.field.bankBeneficiaryName')
            }),
            trigger: ['blur', 'change']
          }
        ],
        accountNumber: [
          {
            required: true,
            message: this.$t('common.formValidation.accReq'),
            trigger: ['blur', 'change']
          }
        ],
        bankProvince: [
          {
            required: true,
            message: this.$t('common.formValidation.bankProvinceReq'),
            trigger: ['blur', 'change']
          }
        ],
        bankCity: [
          {
            required: true,
            message: this.$t('common.formValidation.bankCityReq'),
            trigger: ['blur', 'change']
          }
        ],
        uploadedFile: [
          {
            required: true,
            validator: validateFile,
            trigger: 'change'
          }
        ]
      },
      fileList: []
    };
  },
  methods: {
    updateFileInfo(fileInfo) {
      this.fileList = fileInfo.fileList;
      this.$refs['form'].validateField('uploadedFile');
    },
    getFormData() {
      return {
        qAccount: this.accountNumber,
        amount: this.amount,
        withdrawalType: this.withdrawalType,
        bankName: this.form.bankName,
        beneficiaryName: this.form.beneficiaryName,
        accountNumber: this.form.accountNumber,
        bankProvince: this.form.bankProvince,
        bankCity: this.form.bankCity,
        importantNotes: this.form.importantNotes,
        isRememberInfo: this.form.userPaymentInfoId ? '' : this.isRememberInfo,
        userPaymentInfoId: this.form.userPaymentInfoId ? this.form.userPaymentInfoId : '',
        fileList: this.fileList
      };
    }
  },
  watch: {
    'form.selectedCardID'(value) {
      const selectedCardInfo = this.availableCards.find(item => item.id === value);
      const isCurrentCard = selectedCardInfo && selectedCardInfo.id !== -1;
      this.form = {
        selectedCardID: value,
        bankName: selectedCardInfo.bankName || '',
        beneficiaryName: selectedCardInfo.beneficiaryName || '',
        accountNumber: selectedCardInfo.accountNumber || '',
        bankProvince: selectedCardInfo.bankProvince || '',
        bankCity: selectedCardInfo.bankCity || '',
        importantNotes: selectedCardInfo.importantNotes || '',
        userPaymentInfoId: isCurrentCard ? selectedCardInfo.id : null
      };
      this.isdisabled = isCurrentCard ? true : false;
    },
    'form.accountNumber'(value) {
      this.form.accountNumber = this.latinNumberValidator(value);
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/components/withdraw/banktransfer.scss';
</style>
