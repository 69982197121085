<template>
  <div id="fasapayForm">
    <el-form label-position="top" :model="form" ref="form" :rules="rules">
      <el-row :gutter="40">
        <el-col :xs="24" :sm="12">
          <el-form-item :label="$t('withdraw.fasa.field.accName')" prop="accountName">
            <el-input v-model="form.accountName" data-testid="accountName"></el-input> </el-form-item
        ></el-col>
        <el-col :xs="24" :sm="12">
          <el-form-item :label="$t('withdraw.fasa.field.accNum')" prop="accountNumber">
            <el-input v-model="form.accountNumber" data-testid="accountNumber"></el-input> </el-form-item
        ></el-col>
      </el-row>
      <el-row :gutter="40" type="flex">
        <el-col :span="24">
          <el-form-item :label="$t('common.field.imptNotes')" prop="importantNotes">
            <el-input v-model="form.importantNotes" data-testid="importantNotes"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <p class="alert_info">
        {{ $t('withdraw.default.FasaPayChargeAlert') }}
      </p>
    </el-form>
  </div>
</template>

<script>
export default {
  name: 'fasapayWithdraw',
  props: { accountNumber: Number, amount: Number, withdrawalType: Number },
  data() {
    return {
      form: {
        accountName: '',
        accountNumber: '',
        importantNotes: ''
      },
      rules: {
        accountName: [
          {
            required: true,
            message: this.$t('withdraw.fasa.formValidation.accNameReq'),
            trigger: 'blur'
          }
        ],
        accountNumber: [
          {
            required: true,
            message: this.$t('withdraw.fasa.formValidation.accNumReq'),
            trigger: 'blur'
          }
        ]
      }
    };
  },
  methods: {
    getFormData() {
      return {
        qAccount: this.accountNumber,
        amount: this.amount,
        withdrawalType: this.withdrawalType,
        accountName: this.form.accountName,
        accountNumber: this.form.accountNumber,
        importantNotes: this.form.importantNotes
      };
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/components/withdraw/default.scss';
</style>
