/**
 * For CPS Withdraw Listing Ordering
 * (CPS Module)
 */

import { countryCodeEnum } from '@/constants/country'

export const withdrawListingIndex = [
  // Country Specific Sample
  // {
  //   id: countryCodeEnum.INDIA,
  //   order: [31, 7, 4],
  // },
  {
    id: 'global',
    order: [],
  },
  {
    id: countryCodeEnum.IRAN,
    order: [97],
  },
]
