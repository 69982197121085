<template>
  <div id="indiaForm" class="bankForm_wrapper">
    <el-form label-position="top" :model="form" ref="form" :rules="rules">
      <el-row :gutter="10">
        <el-col :xs="24" :sm="11" class="label_wrapper">
          <div class="label fl">{{ $t('common.withdrawChannel.banktransfer') }}</div>
          <el-form-item prop="selectedCardID" class="fr">
            <el-select v-model="form.selectedCardID" data-testid="selectedCardID">
              <el-option
                v-for="item in availableCards"
                :key="item.id"
                :value="item.id"
                :label="item.id | cardInfoDropdown(item, withdrawalType, dropdownTranslationObj)"
                :data-testid="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :xs="24" :sm="11" class="label_wrapper">
          <div class="label fl2 label_margin-left">{{ $t('common.field.amt') }}</div>
          <el-form-item prop="availableAmount" class="fr2">
            <el-input data-testid="availableAmount" v-model="amount" disabled></el-input> </el-form-item
        ></el-col>
      </el-row>

      <div v-if="form.selectedCardID || form.selectedCardID === 0">
        <el-row :gutter="20">
          <el-col :xs="24" :sm="8">
            <el-form-item :label="$t('common.field.bankName')" prop="bankName" required>
              <el-input v-model="form.bankName" :disabled="isdisabled" data-testid="bankName"></el-input>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="8">
            <el-form-item :label="$t('common.field.bankAddress')" prop="bankAddress" required>
              <el-input v-model="form.bankAddress" :disabled="isdisabled" data-testid="bankAddress"></el-input>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="8">
            <el-form-item :label="$t('common.field.bankBeneficiaryName')" prop="beneficiaryName">
              <el-input v-model="form.beneficiaryName" :disabled="isdisabled" data-testid="beneficiaryName"></el-input>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row :gutter="20">
          <el-col :xs="24" :sm="8">
            <el-form-item :label="$t('common.field.bankAccNum')" prop="accountNumber">
              <el-input v-model="form.accountNumber" :disabled="isdisabled" data-testid="accountNumber"></el-input>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="8">
            <el-form-item :label="$t('common.field.bankBranch')" prop="bankBranchName">
              <el-input v-model="form.bankBranchName" :disabled="isdisabled" data-testid="bankBranchName"></el-input>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="8">
            <el-form-item :label="$t('common.field.ifscCode')" prop="ifscCode" required>
              <el-input v-model="form.ifscCode" :disabled="isdisabled" data-testid="ifscCode"></el-input>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row :gutter="20">
          <el-col :xs="24" :sm="8">
            <el-form-item :label="$t('register.personalDetails.page1.email')" prop="email">
              <el-input v-model="form.email" type="email" data-testid="email"></el-input>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="8">
            <el-form-item :label="$t('common.field.phone')" prop="phoneNumber">
              <el-input v-model="form.phoneNumber" data-testid="phoneNumber"></el-input>
            </el-form-item>
          </el-col>
          <el-col ::xs="24" :sm="8">
            <el-form-item :label="$t('common.field.imptNotes')" prop="importantNotes">
              <el-input v-model="form.importantNotes" data-testid="importantNotes"></el-input>
            </el-form-item>
          </el-col>
        </el-row>

        <div class="checkbox_wrapper" v-if="!form.userPaymentInfoId">
          <el-checkbox v-model="isRememberInfo" data-testid="checkbox">
            {{ $t('withdraw.default.remember') }}</el-checkbox
          >
        </div>
      </div>
    </el-form>
  </div>
</template>

<script>
import withdrawlMixin from '@/mixins/withdrawl/withdrawl';
import bankTransferMixin from '@/mixins/withdrawl/bankTransfer';
import { validateEmail } from '@/util/validation';

export default {
  name: 'indiaWithdraw',
  props: { accountNumber: Number, amount: Number, withdrawalType: Number },
  mixins: [withdrawlMixin, bankTransferMixin],
  data() {
    return {
      form: {
        bankName: '',
        bankAddress: '',
        beneficiaryName: '',
        accountNumber: '',
        email: '',
        phoneNumber: '',
        importantNotes: '',
        ifscCode: '',
        bankBranchName: '',
        userPaymentInfoId: null
      },
      rules: {
        bankName: [
          {
            required: true,
            message: this.$t('common.formValidation.bankNameReq'),
            trigger: ['blur', 'change']
          }
        ],
        bankAddress: [
          {
            required: true,
            message: this.$t('common.formValidation.bankAddressReq'),
            trigger: ['blur', 'change']
          }
        ],
        beneficiaryName: [
          {
            required: true,
            message: this.$t('common.formValidation.bankBeneficiaryNameReq'),
            trigger: ['blur', 'change']
          }
        ],
        accountNumber: [
          {
            required: true,
            message: this.$t('common.formValidation.bankAccNumReq'),
            trigger: ['blur', 'change']
          }
        ],
        ifscCode: [
          {
            required: true,
            message: this.$t('common.formValidation.dynamicReq', {
              dynamic: this.$t('common.field.ifscCode')
            }),
            trigger: ['blur', 'change']
          }
        ],
        bankBranchName: [
          {
            required: true,
            message: this.$t('common.formValidation.bankBranchReq'),
            trigger: ['blur', 'change']
          }
        ],
        email: [
          {
            required: true,
            message: this.$t('common.formValidation.dynamicReq', {
              dynamic: this.$t('register.personalDetails.page1.email')
            }),
            trigger: 'blur'
          },
          {
            validator: validateEmail,
            trigger: 'blur'
          }
        ],
        phoneNumber: [
          {
            required: true,
            message: this.$t('common.formValidation.dynamicReq', { dynamic: this.$t('common.field.phone') }),
            trigger: 'blur'
          }
        ]
      }
    };
  },
  methods: {
    getFormData() {
      return {
        qAccount: this.accountNumber,
        amount: this.amount,
        withdrawalType: this.withdrawalType,
        bankName: this.form.bankName,
        bankAddress: this.form.bankAddress,
        beneficiaryName: this.form.beneficiaryName,
        accountNumber: this.form.accountNumber,
        bankBranchName: this.form.bankBranchName,
        ifscCode: this.form.ifscCode,
        email: this.form.email,
        phoneNumber: this.form.phoneNumber,
        importantNotes: this.form.importantNotes,
        isRememberInfo: this.form.userPaymentInfoId ? '' : this.isRememberInfo,
        userPaymentInfoId: this.form.userPaymentInfoId ? this.form.userPaymentInfoId : ''
      };
    }
  },
  watch: {
    'form.selectedCardID'(value) {
      const selectedCardInfo = this.availableCards.find(item => item.id === value);
      const isCurrentCard = selectedCardInfo && selectedCardInfo.id !== -1;

      console.log(selectedCardInfo);
      this.form = {
        selectedCardID: value,
        bankName: selectedCardInfo.bankName || '',
        bankAddress: selectedCardInfo.bankAddress || '',
        beneficiaryName: selectedCardInfo.beneficiaryName || '',
        accountNumber: selectedCardInfo.accountNumber || '',
        bankBranchName: selectedCardInfo.bankBranchName || '',
        ifscCode: selectedCardInfo.ifscCode || '',
        email: selectedCardInfo.email || '',
        phoneNumber: selectedCardInfo.phoneNumber || '',
        importantNotes: selectedCardInfo.importantNotes || '',
        userPaymentInfoId: isCurrentCard ? selectedCardInfo.id : null
      };
      this.isdisabled = isCurrentCard ? true : false;
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/components/withdraw/banktransfer.scss';
</style>
