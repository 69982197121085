<template>
  <div id="hongkong" class="bankForm_wrapper">
    <el-form label-position="top" :model="form" ref="form" :rules="rules">
      <el-row :gutter="10">
        <el-col :xs="24" :sm="11" class="label_wrapper">
          <div class="label fl">{{ $t('common.withdrawChannel.banktransfer') }}</div>
          <el-form-item prop="selectedCardID" class="fr">
            <el-select v-model="form.selectedCardID" data-testid="selectedCardID">
              <el-option
                v-for="item in availableCards"
                :key="item.id"
                :value="item.id"
                :label="item.id | cardInfoDropdown(item, withdrawalType, dropdownTranslationObj)"
                :data-testid="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :xs="24" :sm="11" class="label_wrapper">
          <div class="label fl2 label_margin-left">{{ $t('common.field.amt') }}</div>
          <el-form-item prop="availableAmount" class="fr2">
            <el-input data-testid="availableAmount" v-model="amount" disabled></el-input> </el-form-item
        ></el-col>
      </el-row>
      <div v-if="form.selectedCardID || form.selectedCardID === 0">
        <el-row :gutter="20">
          <el-col :xs="24" :sm="8">
            <el-form-item :label="$t('common.field.bankCode')" prop="bankName" required>
              <el-select
                v-model="form.bankName"
                :disabled="isdisabled"
                @change="handleBankCodeChange"
                data-testid="bankName"
              >
                <el-option v-for="bank in banks" :key="bank.bankCode" :value="bank.value"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="8">
            <el-form-item :label="$t('common.field.bankBranchCode')" prop="bankBranchName">
              <el-select v-model="form.bankBranchName" :disabled="isdisabled">
                <el-option
                  v-for="bankBranch in bankBranches"
                  :key="bankBranch.branchCode"
                  :value="bankBranch.value"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="8">
            <el-form-item :label="$t('common.field.bankAccName')" prop="bankAccName" required>
              <el-input v-model="form.bankAccName" :disabled="isdisabled" data-testid="bankAccName"></el-input>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="8">
            <el-form-item :label="$t('common.field.bankAccNum')" prop="accountNumber">
              <el-input v-model="form.accountNumber" :disabled="isdisabled" data-testid="accountNumber"></el-input>
            </el-form-item>
          </el-col>

          <el-col :xs="24" :sm="8" v-if="currentCurrency === 'USD'">
            <el-form-item :label="$t('common.field.bankAccountCurrency')" prop="withdrawCurrency">
              <el-select v-model="form.withdrawCurrency" data-testid="withdrawCurrency">
                <el-option
                  v-for="currency in supportedCurrencies"
                  :key="currency"
                  :value="currency"
                  :label="currency"
                  :data-testid="currency"
                ></el-option>
              </el-select>
            </el-form-item>
            <div class="mt-n3 mb-4" v-if="isHKD">
              {{ $t('deposit.default.rate.rate', { oldCurrency: 'USD', newCurrency: 'HKD' }) }}
              <span>{{ rate }}</span>
            </div>
          </el-col>
          <el-col :xs="24" :sm="currentCurrency === 'USD' ? 8 : 16">
            <el-form-item :label="$t('common.field.imptNotes')" prop="importantNotes">
              <el-input v-model="form.importantNotes" data-testid="importantNotes"></el-input>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row type="flex" class="row-bg" justify="space-between">
          <el-col :span="24" v-if="!isdisabled">
            <el-form-item :label="$t('withdraw.transfer.label.upload')" class="upload" prop="uploadedFile">
              <vUpload :limit="1" v-on:updateFileInfo="updateFileInfo" data-testid="uploadedFile"></vUpload>
            </el-form-item>
          </el-col>
        </el-row>

        <div class="checkbox_wrapper" v-if="!form.userPaymentInfoId">
          <el-checkbox v-model="isRememberInfo" data-testid="checkbox">
            {{ $t('withdraw.default.remember') }}</el-checkbox
          >
        </div>
      </div>
    </el-form>
  </div>
</template>

<script>
import withdrawlMixin from '@/mixins/withdrawl/withdrawl';
import bankTransferMixin from '@/mixins/withdrawl/bankTransfer';
import vUpload from '@/components/vUpload';
import {
  apiWithdrawalQueryRateUSDByTargetCurrency,
  apiGetWithdrawalBankCode,
  apiGetWithdrawalBankBranchCode
} from '@/resource';

export default {
  name: 'HongKongWithdraw',
  components: { vUpload },
  props: { accountNumber: Number, amount: Number, withdrawalType: Number, currentCurrency: String },
  mixins: [bankTransferMixin, withdrawlMixin],
  data() {
    const validateFile = (rule, value, callback) => {
      if (this.fileList.length < 1) callback(new Error(this.$t('withdraw.transfer.formValidation.upload')));
      else callback();
    };
    return {
      form: {
        bankName: '',
        bankBranchName: '',
        bankAccName: '',
        accountNumber: '',
        importantNotes: '',
        userPaymentInfoId: null,
        withdrawCurrency: ''
      },
      banks: [],
      bankBranches: [],
      isHKD: false,
      rate: 7.75,
      supportedCurrencies: ['HKD', 'USD'],
      rules: {
        bankName: [
          {
            required: true,
            message: this.$t('common.formValidation.bankCodeReq'),
            trigger: ['blur', 'change']
          }
        ],
        bankBranchName: [
          {
            required: true,
            message: this.$t('common.formValidation.bankBranchCodeReq'),
            trigger: ['blur', 'change']
          }
        ],
        bankAccName: [
          {
            required: true,
            message: this.$t('common.formValidation.dynamicReq', { dynamic: this.$t('common.field.bankAccName') }),
            trigger: ['blur', 'change']
          }
        ],
        accountNumber: [
          {
            required: true,
            message: this.$t('common.formValidation.bankAccNumReq'),
            trigger: ['blur', 'change']
          }
        ],
        withdrawCurrency: [
          {
            required: true,
            message: this.$t('common.formValidation.dynamicReq', {
              dynamic: this.$t('common.field.bankAccountCurrency')
            }),
            trigger: 'change'
          }
        ],
        uploadedFile: [
          {
            required: true,
            validator: validateFile,
            trigger: 'change'
          }
        ]
      },
      fileList: []
    };
  },
  methods: {
    updateFileInfo(fileInfo) {
      this.fileList = fileInfo.fileList;
      this.$refs['form'].validateField('uploadedFile');
    },
    getFormData() {
      return {
        qAccount: this.accountNumber,
        amount: this.amount,
        withdrawalType: this.withdrawalType,
        bankName: this.form.bankName,
        bankAccountName: this.form.bankAccName,
        accountNumber: this.form.accountNumber,
        bankBranchName: this.form.bankBranchName,
        importantNotes: this.form.importantNotes,
        isRememberInfo: this.form.userPaymentInfoId ? '' : this.isRememberInfo,
        userPaymentInfoId: this.form.userPaymentInfoId ? this.form.userPaymentInfoId : '',
        targetCurrency: this.form.withdrawCurrency,
        fileList: this.fileList
      };
    },
    queryRate() {
      apiWithdrawalQueryRateUSDByTargetCurrency('HKD')
        .then(resp => {
          if (resp.data.code == 0) {
            switch (this.accountCurrency) {
              case 'USC':
                this.rate = resp.data.data / 100;
                break;
              default:
                this.rate = resp.data.data;
                break;
            }
          }
        })
        .catch(err => {
          this.errorMessage(
            this.$t('deposit.default.rate.result.rateError', { oldCurrency: this.accountCurrency, newCurrency: 'HKD' })
          );
        });
    },
    queryBankCode() {
      apiGetWithdrawalBankCode()
        .then(resp => {
          if (resp.data.code == 0)
            this.banks = resp.data.data.map(bank => ({ ...bank, value: bank.bankCode + ' - ' + bank.bankName }));
        })
        .catch(err => {
          this.errorMessage(this.$t('Get Bank Code Error.'));
        });
    },
    queryBankBranchCode(bankCode) {
      apiGetWithdrawalBankBranchCode(bankCode)
        .then(resp => {
          if (resp.data.code == 0)
            this.bankBranches = resp.data.data.map(bank => ({
              ...bank,
              value: bank.branchCode + ' - ' + bank.branchName
            }));
        })
        .catch(err => {
          this.errorMessage(this.$t('Get Bank Branch Code Error.'));
        });
    },
    handleBankCodeChange(value) {
      let currentBankCode = this.banks.find(bank => bank.value === value).bankCode;
      this.queryBankBranchCode(currentBankCode);
      this.form.bankBranchName = '';
    }
  },
  watch: {
    'form.selectedCardID'(value) {
      const selectedCardInfo = this.availableCards.find(item => item.id === value);
      const isCurrentCard = selectedCardInfo && selectedCardInfo.id !== -1;

      this.form = {
        selectedCardID: value,
        bankName: selectedCardInfo.bankName || '',
        bankBranchName: selectedCardInfo.bankBranchName || '',
        bankAccName: selectedCardInfo.bankAccountName || '',
        accountNumber: selectedCardInfo.accountNumber || '',
        importantNotes: selectedCardInfo.importantNotes || '',
        userPaymentInfoId: isCurrentCard ? selectedCardInfo.id : null,
        withdrawCurrency: selectedCardInfo.withdrawCurrency || this.currentCurrency === 'USD' ? '' : 'HKD'
      };
      this.isdisabled = isCurrentCard ? true : false;
    },
    'form.withdrawCurrency'(value) {
      if (value === 'HKD') this.isHKD = true;
      else this.isHKD = false;
    }
  },
  mounted() {
    this.queryRate();
    this.queryBankCode();
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/components/withdraw/banktransfer.scss';
</style>
