<template>
  <el-dialog
    :title="title"
    :top="top"
    :width="width"
    :visible.sync="show"
    custom-class="vDialog"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
  >
    <div slot="title"><slot name="title"></slot></div>
    <slot></slot>
    <div slot="footer" class="dialog-footer"><slot name="footer"></slot></div>
  </el-dialog>
</template>

<script>
export default {
  name: 'vDialog',
  props: {
    show: Boolean,
    top: { type: String, default: '30px' },
    width: { type: String, default: '600px' },
    title: { type: String, default: '' }
  }
};
</script>
