<template>
  <div id="usdtWithdraw">
    <el-form label-position="top" :model="form" ref="form" :rules="rules">
      <el-row :gutter="40">
        <el-col :xs="24" :sm="8">
          <el-form-item :label="$t('withdraw.usdt.field.chain')" prop="cryptoWalletChain">
            <el-select v-model="form.cryptoWalletChain" data-testid="chain">
              <el-option
                v-for="option in formChainOptions"
                :key="option.value"
                :label="option.text"
                :value="option.value"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :xs="24" :sm="8">
          <el-form-item :label="$t('withdraw.usdt.field.address')" prop="cryptoWalletAddress">
            <el-input v-model="form.cryptoWalletAddress" maxlength="64" data-testid="cryptoWalletAddress"></el-input>
          </el-form-item>
        </el-col>
        <el-col :xs="24" :sm="8">
          <el-form-item :label="$t('common.field.imptNotes')" prop="importantNotes">
            <el-input v-model="form.importantNotes" data-testid="importantNotes"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <p class="alert_info">{{ $t('deposit.crypto.remark') }}</p>
      <br />
      <p v-if="showUSDTNote" class="alert_info">{{ $t('withdraw.usdt.note') }}</p>
    </el-form>
  </div>
</template>

<script>
export default {
  name: 'usdtWithdraw',
  props: { accountNumber: Number, amount: Number, withdrawalType: Number },
  data() {
    return {
      formChainOptions: [
        { text: 'TRC20', value: 'trc20' },
        { text: 'ERC20', value: 'erc20' }
      ],
      form: {
        cryptoWalletAddress: '',
        importantNotes: '',
        cryptoWalletChain: 'trc20'
      },
      rules: {
        cryptoWalletAddress: [
          {
            required: true,
            message: this.$t('withdraw.usdt.formValidation.addressReq'),
            trigger: 'blur'
          },
          {
            validator: (rule, value, callback) => {
              const chainType = this.form.cryptoWalletChain;

              if (chainType === 'trc20' && !/^T/.test(value)) {
                callback(new Error(this.$t('withdraw.usdt.formValidation.walletFormatIncorrect', { type: 'TRC20' })));
              } else if (chainType === 'erc20' && !/^0x/.test(value)) {
                callback(new Error(this.$t('withdraw.usdt.formValidation.walletFormatIncorrect', { type: 'ERC20' })));
              } else {
                callback();
              }
            },
            trigger: ['blur', 'change']
          }
        ],
        cryptoWalletChain: [
          {
            required: true,
            message: this.$t('withdraw.usdt.formValidation.chain'),
            trigger: 'change'
          },
          {
            validator: (rule, value, callback) => {
              this.$refs.form.validateField('cryptoWalletAddress');
              callback();
            },
            trigger: 'change'
          }
        ]
      }
    };
  },
  computed: {
    showUSDTNote() {
      return this.form.cryptoWalletChain === 'erc20';
    }
  },
  methods: {
    getFormData() {
      return {
        qAccount: this.accountNumber,
        amount: this.amount,
        withdrawalType: this.withdrawalType,
        cryptoWalletAddress: this.form.cryptoWalletAddress,
        importantNotes: this.form.importantNotes
      };
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/components/withdraw/default.scss';
</style>
