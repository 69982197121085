<template>
  <div id="netellerForm">
    <el-form label-position="top" :model="form" ref="form" :rules="rules">
      <el-row :gutter="40">
        <el-col :xs="24" :sm="12">
          <el-form-item :label="$t('withdraw.neteller.field.email')" prop="netellerEmail">
            <el-input v-model="form.netellerEmail" type="email" data-testid="netellerEmail"></el-input>
          </el-form-item>
        </el-col>
        <el-col :xs="24" :sm="12">
          <el-form-item :label="$t('common.field.imptNotes')" prop="importantNotes">
            <el-input v-model="form.importantNotes" data-testid="importantNotes"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <p class="alert_info" v-if="isAlert">
        {{ $t('withdraw.default.NetellerChargeAlert') }}
      </p>
    </el-form>
  </div>
</template>

<script>
import { validateEmail } from '@/util/validation';

export default {
  name: 'netellerWithdraw',
  props: { accountNumber: Number, amount: Number, withdrawalType: Number },
  data() {
    return {
      form: {
        netellerEmail: '',
        importantNotes: ''
      },
      rules: {
        netellerEmail: [
          {
            required: true,
            message: this.$t('withdraw.neteller.formValidation.emailReq'),
            trigger: 'blur'
          },
          {
            validator: validateEmail,
            trigger: 'blur'
          }
        ]
      },
      //Kazakhstan, Russia, Latvia, Vietnam
      alertCountryList: [4266, 3983, 4810, 6991]
    };
  },
  computed: {
    countryCode() {
      return this.$store.state.common.countryCode;
    },
    isAlert() {
      return this.alertCountryList.includes(parseInt(this.countryCode));
    }
  },
  methods: {
    getFormData() {
      return {
        qAccount: this.accountNumber,
        amount: this.amount,
        withdrawalType: this.withdrawalType,
        skrillEmail: this.form.netellerEmail,
        importantNotes: this.form.importantNotes
      };
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/components/withdraw/default.scss';
</style>
