<template>
  <!-- Dasshpe UPI -->
  <div id="form">
    <el-form label-position="top" :model="form" ref="form" :rules="rules">
      <el-row :gutter="40">
        <el-col :xs="24" :sm="12">
          <el-form-item :label="$t('common.field.vpa')" prop="vpa">
            <el-input v-model="form.vpa" data-testid="vpa" maxlength="100"></el-input>
          </el-form-item>
        </el-col>
        <el-col :xs="24" :sm="12">
          <el-form-item :label="$t('common.field.name')" prop="name">
            <el-input v-model="form.name" data-testid="name"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="40">
        <el-col :xs="24" :sm="12">
          <el-form-item :label="$t('register.personalDetails.page1.email')" prop="email">
            <el-input v-model="form.email" type="email" data-testid="email"></el-input>
          </el-form-item>
        </el-col>
        <el-col :xs="24" :sm="12">
          <el-form-item :label="$t('common.field.phone')" prop="phone">
            <el-input v-model="form.phone" data-testid="phone"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="40">
        <el-col :xs="24" :sm="12">
          <el-form-item :label="$t('common.field.address')" prop="address">
            <el-input v-model="form.address" data-testid="address"></el-input>
          </el-form-item>
        </el-col>
        <el-col :xs="24" :sm="12">
          <el-form-item :label="$t('common.field.imptNotes')" prop="importantNotes">
            <el-input v-model="form.importantNotes" data-testid="importantNotes"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
  </div>
</template>

<script>
import { validateEmail } from '@/util/validation';

export default {
  name: 'DasshpeUPIWithdraw',
  props: { accountNumber: Number, amount: Number, withdrawalType: Number },
  data() {
    return {
      form: {
        vpa: '',
        name: '',
        email: '',
        phone: '',
        address: '',
        importantNotes: ''
      },
      rules: {
        email: [
          {
            required: true,
            message: this.$t('common.formValidation.dynamicReq', {
              dynamic: this.$t('register.personalDetails.page1.email')
            }),
            trigger: 'blur'
          },
          {
            validator: validateEmail,
            trigger: 'blur'
          }
        ],
        vpa: [
          {
            required: true,
            message: this.$t('common.formValidation.dynamicReq', { dynamic: this.$t('common.field.vpa') }),
            trigger: 'blur'
          }
        ],
        name: [
          {
            required: true,
            message: this.$t('common.formValidation.dynamicReq', { dynamic: this.$t('common.field.name') }),
            trigger: 'blur'
          }
        ],
        phone: [
          {
            required: true,
            message: this.$t('common.formValidation.dynamicReq', { dynamic: this.$t('common.field.phone') }),
            trigger: 'blur'
          }
        ],
        address: [
          {
            required: true,
            message: this.$t('common.formValidation.dynamicReq', { dynamic: this.$t('common.field.address') }),
            trigger: 'blur'
          }
        ]
      }
    };
  },
  methods: {
    getFormData() {
      return {
        qAccount: this.accountNumber,
        amount: this.amount,
        withdrawalType: this.withdrawalType,
        vpa: this.form.vpa,
        upiName: this.form.name,
        upiEmail: this.form.email,
        upiPhoneNo: this.form.phone,
        upiAddress: this.form.address,
        importantNotes: this.form.importantNotes
      };
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/components/withdraw/default.scss';
</style>
