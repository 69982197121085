<template>
  <el-dialog :visible.sync="visible" width="500px" :before-close="handleClose" center>
    <div class="title text-white">{{ $t('security.withdrawalFreeze.header') }}</div>
    <div class="description text-white">
      <template v-if="fromFunctionCode === 'updateUserLogin'">
        {{ $t('security.withdrawalFreeze.userLoginDesc', { days: limitDayNumber, hours: lastHours }) }}
      </template>
      <template v-if="fromFunctionCode === 'updatePassword'">
        {{ $t('security.withdrawalFreeze.passDesc', { days: limitDayNumber, hours: lastHours }) }}
      </template>
      <template v-if="fromFunctionCode === 'changeAuthenticator'">
        {{ $t('security.withdrawalFreeze.limitSecurityAuthenticator', { days: limitDayNumber, hours: lastHours }) }}
      </template>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button type="primary" @click="handleClose" class="confirmBtn">{{ $t('security.understand') }}</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { apiGetSecurity } from '@/resource';

export default {
  name: 'WithdrawalFreezeDialog',
  props: {
    freezeDialogVisible: { type: Boolean, default: false },
    selectedWithdrawalMethod: { type: Number }
  },
  data() {
    return {
      visible: false,
      fromFunctionCode: undefined,
      limitDayNumber: undefined,
      lastHours: undefined,
      limitWithdrawTypes: []
    };
  },
  watch: {
    freezeDialogVisible(freezeDialogVisible) {
      if (freezeDialogVisible) {
        this.confirmShowFreezeDialog();
      }
    },
    visible(bool) {
      this.$emit('update:freezeDialogVisible', bool);
    }
  },
  methods: {
    handleVisible(bool) {
      this.visible = bool;
      this.$emit('update:freezeDialogVisible', bool);
    },
    handleClose() {
      this.handleVisible(false);
    },
    confirmShowFreezeDialog() {
      this.getFreezePeriod()
        .then(resp => {
          if (resp && this.fromFunctionCode && this.limitDayNumber && this.lastHours) {
            this.handleVisible(true);
            this.$emit('confirmSubmit', null);
          } else {
            this.handleVisible(false);
            this.$emit('confirmSubmit', this.selectedWithdrawalMethod);
          }
        })
        .catch(() => {
          this.handleVisible(false);
        });
    },
    async getFreezePeriod() {
      try {
        let result = false;
        const resp = await apiGetSecurity()
        if (resp.data.code === 0) {
          let data = resp.data.data || [];
            // 按时间排序
            const withdrawLimitArrays = data
              .filter(el => el.functionCode == 'withdraw')
              .sort((a, b) => b.limitTimeUTC - a.limitTimeUTC);
            // 找到按时间排序后并且包含该选中渠道的第一个限制
            const currLimitObj = withdrawLimitArrays.find(item => {
              let curr = item.limitWithdrawTypes ? item.limitWithdrawTypes.split(',').map(item => Number(item)) : [];
              return curr.includes(this.selectedWithdrawalMethod);
            });
            // 如果找到限制
            if (currLimitObj && Object.keys(currLimitObj).length > 0) {
              this.fromFunctionCode = currLimitObj.fromFunctionCode;
              this.limitDayNumber = currLimitObj.limitDayNumber;
              this.lastHours = currLimitObj.lastHours;
              this.limitWithdrawTypes = currLimitObj.limitWithdrawTypes?.length
                ? currLimitObj.limitWithdrawTypes.split(',').map(Number)
                : undefined;
              result = true;
            } else {
              result = false;
            }
        } else {
          console.error('Get limit day number fails');
        }
        return result;
      } catch (error) {
        console.error('Error occurred while fetching data:', error);
      }
    }
  }
};
</script>

<style scoped lang="scss">
.title {
  font-size: 18px;
  font-weight: 700;
  text-align: center;
  margin-top: 10px;
  margin-bottom: 24px;
  line-height: 160%;
}
.description {
  font-size: 14px;
  font-weight: 500;
  text-align: center;
  margin: 0 30px;
  line-height: 160%;
}
/deep/ .el-dialog__footer {
  padding-bottom: 60px;
}
.confirmBtn {
  width: calc(100% - 70px);
  margin: 0 35px;
}
</style>
