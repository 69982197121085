<template>
  <div id="bitcoinWithdraw">
    <el-form label-position="top" :model="form" ref="form" :rules="rules">
      <el-row :gutter="40">
        <el-col :xs="24" :sm="12">
          <el-form-item :label="$t('withdraw.bitcoin.field.address')" prop="cryptoWalletAddress">
            <el-input v-model="form.cryptoWalletAddress" maxlength="64" data-testid="cryptoWalletAddress"></el-input>
          </el-form-item>
        </el-col>
        <el-col :xs="24" :sm="12">
          <el-form-item :label="$t('common.field.imptNotes')" prop="importantNotes">
            <el-input v-model="form.importantNotes" data-testid="importantNotes"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <p class="alert_info">{{ $t('deposit.crypto.remark') }}</p>
    </el-form>
  </div>
</template>

<script>
export default {
  name: 'bitcoinWithdraw',
  props: { accountNumber: Number, amount: Number, withdrawalType: Number },
  data() {
    const validateAddress = (rule, value, callback) => {
      if (value === '') {
        callback(new Error(this.$t('withdraw.bitcoin.formValidation.addressReq')));
      } else if (!/^(bc|[13])/.test(value)) {
        callback(new Error(this.$t('withdraw.bitcoin.formValidation.addressFormat')));
      } else callback();
    };
    return {
      form: {
        cryptoWalletAddress: '',
        importantNotes: ''
      },
      rules: {
        cryptoWalletAddress: [
          {
            required: true,
            validator: validateAddress,
            trigger: 'blur'
          }
        ]
      }
    };
  },
  methods: {
    getFormData() {
      return {
        qAccount: this.accountNumber,
        amount: this.amount,
        withdrawalType: this.withdrawalType,
        cryptoWalletAddress: this.form.cryptoWalletAddress,
        importantNotes: this.form.importantNotes
      };
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/components/withdraw/default.scss';
</style>
