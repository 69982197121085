<template>
  <div id="withdrawalMethod">
    <el-form label-position="top" :model="form" ref="form" :rules="rules">
      <el-row :gutter="40" class="top">
        <el-col :xs="24" :sm="12">
          <div class="title">
            {{ title }}
          </div>
        </el-col>
        <el-col :xs="24" :sm="12" class="totalAmount"> {{ $t('common.field.amt') }}: {{ amount }} </el-col>
      </el-row>
      <el-row>
        <el-col :xs="24">
          <p class="desc">
            <span>{{ $t('withdraw.otherMethod.desc1') }}</span>
            <br />
            <span>{{ $t('withdraw.otherMethod.desc2') }}</span>
          </p>
        </el-col>
      </el-row>
      <el-row>
        <el-col :xs="24" :sm="12" class="availableTypes">
          <el-form-item prop="type">
            <el-select v-model="form.type" data-testid="type" @change="handleOptionChange">
              <el-option
                v-for="item in withdrawalTypes"
                :value="item.id"
                :label="getTitleLabel(item.nameKey)"
                :data-testid="item.cps ? 'cps-' + item.id : item.id"
                :key="item.cps ? 'cps-' + item.id : item.id"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row v-if="showNote">
        <el-col :xs="24">
          <p class="note" v-html="$t('withdraw.otherMethod.note', { mail: GLOBAL_CONTACT_EMAIL })"></p>
        </el-col>
      </el-row>
      <p v-if="isSelectedCrypto" class="note">{{ $t('withdraw.otherMethod.note2') }}</p>
    </el-form>
    <component
      :is="currentComponent"
      ref="child"
      :amount="amount"
      :accountNumber="withdrawForm.account"
      :currentCurrency="withdrawForm.currency"
      :withdrawalType="form.type"
      :availableTypes="availableTypes"
      :chosenTypeInfo="chosenTypeInfo"
      :userCountryCode="topForm.userCountryCode"
      :bankList="withdrawalBankList"
    ></component>
    <p
      v-if="checkMethodMinAmount && belowMinimumAmount"
      class="alert"
      v-html="
        $t('withdraw.default.minAmountWarn', {
          regulatorUrl: GLOBAL_DOMAIN_WEBSITE
        })
      "
    ></p>
    <WithdrawalFreezeDialog
      :freezeDialogVisible.sync="freezeDialogVisible"
      :selectedWithdrawalMethod="selectedWithdrawalMethod"
      @confirmSubmit="updateWithdrawalMethod"
    />
  </div>
</template>

<script>
import BankTransfer from '@/components/withdraw/BankTransfer';
import ADVCashWithdraw from '@/components/withdraw/ADVCash';
import SkrillWithdraw from '@/components/withdraw/SkrillWithdraw';
import NetellerWithdraw from '@/components/withdraw/NetellerWithdraw';
import FasapayWithdraw from '@/components/withdraw/FasapayWithdraw';
import ThaMalVieWithdraw from '@/components/withdraw/ThaMalVieWithdraw';
import CpsWithdraw from '@/components/withdraw/CpsWithdraw';
import NigeriaWithdraw from '@/components/withdraw/NigeriaWithdraw';
import UnionPayWithdraw from '@/components/withdraw/UnionpayWithdraw';
import BitcoinWithdraw from '@/components/withdraw/BitcoinWithdraw';
import UsdtWithdraw from '@/components/withdraw/UsdtWithdraw';
import BitwalletWithdraw from '@/components/withdraw/BitwalletWithdraw';
import SticpayWithdraw from '@/components/withdraw/SticpayWithdraw';
import IndiaWithdraw from '@/components/withdraw/IndiaWithdraw';
import IndonesianWithdraw from '@/components/withdraw/IndonesianWithdraw';
import PhpAndSAWithdraw from '@/components/withdraw/PhpAndSAWithdraw';
import AstropayWithdraw from '@/components/withdraw/AstropayWithdraw';
import WebMoneyWithdraw from '@/components/withdraw/WebMoneyWithdraw';
import VloadWithdraw from '@/components/withdraw/VloadWithdraw.vue';
import LaosWithdraw from '@/components/withdraw/LaosWithdraw';
import DasshpeUPIWithdraw from '@/components/withdraw/DasshpeUPIWithdraw';
import HongKongWithdraw from '@/components/withdraw/HongKongWithdraw';
import AfricaWithdraw from '@/components/withdraw/AfricaWithdraw';
import BrazilWithdraw from '@/components/withdraw/BrazilWithdraw';
import MexicoWithdraw from '@/components/withdraw/MexicoWithdraw';
import CryptoCpsEthWithdraw from '@/components/withdraw/CryptoCpsEthWithdraw.vue';
import CryptoCpsUsdcWithdraw from '@/components/withdraw/CryptoCpsUsdcWithdraw.vue';
import { apiGetNonCreditCardWithdrawTypeCP } from '@/resource';
import { apiGetCpsWithdrawalChannel } from '@/resource/cps';
import rounding from '@/util/rounding';
import { countryIsoCode } from '@/constants/countryCode';
import {
  CURRENCY_RESTRICTED_CHANNELS,
  CURRENCY_AVAILABLE_TYPES,
  MULTIPLE_CURRENCY_CHANNELS
} from '@/constants/withdrawChannelCode';
import { withdrawListingIndex } from '@/constants/withdrawListingIndex';
import fieldLabelList from '@/constants/withdrawFieldLabel';
import WithdrawalFreezeDialog from '@/components/withdraw/WithdrawalFreezeDialog.vue';

export default {
  name: 'otherMethod',
  props: {
    withdrawForm: Object,
    topForm: Object,
    bankList: {
      type: Map,
      default: () => {}
    },
    title: String,
    formType: String
  },
  components: {
    WithdrawalFreezeDialog
  },
  data() {
    return {
      form: {
        type: null
      },
      rules: {
        type: [
          {
            required: true,
            message: this.$t('common.formValidation.withdrawMethodReq'),
            trigger: 'change'
          }
        ]
      },
      availableTypes: [],
      withdrawalTypes: [],
      chosenTypeInfo: null,
      currentComponent: null,
      checkMethodMinAmount: false,
      belowMinimumAmount: false,
      showNote: false,
      withdrawalTypesMap: {
        2: BankTransfer,
        4: UnionPayWithdraw,
        5: CpsWithdraw, // Thailand IBT
        // 5: ThaMalVieWithdraw,
        6: CpsWithdraw,
        7: FasapayWithdraw,
        8: CpsWithdraw,
        9: NigeriaWithdraw,
        24: IndiaWithdraw,
        31: SkrillWithdraw,
        32: NetellerWithdraw,
        34: BitcoinWithdraw,
        35: CpsWithdraw, // South Korea local Bank Transfer
        36: UsdtWithdraw,
        37: BitwalletWithdraw,
        38: SticpayWithdraw,
        39: CpsWithdraw,
        40: PhpAndSAWithdraw,
        42: PhpAndSAWithdraw,
        41: AstropayWithdraw,
        43: WebMoneyWithdraw,
        44: VloadWithdraw,
        47: LaosWithdraw,
        48: DasshpeUPIWithdraw,
        49: CpsWithdraw, // Hong Kong
        50: AfricaWithdraw,
        51: AfricaWithdraw,
        52: AfricaWithdraw,
        53: AfricaWithdraw,
        54: AfricaWithdraw,
        55: AfricaWithdraw,
        56: AfricaWithdraw,
        57: AfricaWithdraw,
        58: AfricaWithdraw,
        63: CpsWithdraw, // Mexico
        64: CpsWithdraw, //Brazil
        // 69: ADVCashWithdraw,
        69: CpsWithdraw, // Advcash
        // 75: CpsWithdraw, // ebuy
        80: CryptoCpsEthWithdraw,
        81: CryptoCpsUsdcWithdraw,
        100: CpsWithdraw, //PIX
        101: CpsWithdraw // bank transfer(USD)
      },
      withdrawalLimit: 0,
      withdrawalBankList: [],
      eeziePayCountryList: [5, 6, 8, 39], // thailand, malaysia, vietnam, indonesia
      cpsPayments: [],
      freezeDialogVisible: false,
      selectedWithdrawalMethod: undefined
    };
  },
  mounted() {
    this.fetchWithdrawalMethod();
  },
  watch: {
    'form.type'(type) {
      if (this.cpsPayments.includes(type)) {
        let selectedCps = this.withdrawalTypes.find(w => w.id == type);
        this.cpsSelect(selectedCps.cps);
        this.currentComponent = CpsWithdraw;
      } else {
        this.currentComponent = this.withdrawalTypesMap[type];
      }

      this.chosenTypeInfo = this.withdrawalTypes.find(f => f.id == type);
      this.withdrawalLimit = this.chosenTypeInfo
        ? this.eeziePayCountryList.includes(this.chosenTypeInfo.id)
          ? 30
          : this.chosenTypeInfo.amountLimit
        : 30;
      this.belowMinimumAmount = this.chosenTypeInfo
        ? this.amount < this.chosenTypeInfo.amountLimit
          ? true
          : false
        : false;
      this.withdrawalBankList = this.bankList?.get(type);
      this.$emit('setType', type, this.checkMethodMinAmount, this.withdrawalLimit);
    }
  },
  computed: {
    amount() {
      if (this.formType === 'localBankTransfer') {
        const amountDecimal = parseFloat(
          rounding(Math.round, this.withdrawForm.amount - this.topForm.totalCcBalance, 2)
        );
        return this.topForm.lbtBalance >= amountDecimal ? amountDecimal : this.topForm.lbtBalance;
      } else {
        return parseFloat(
          rounding(Math.round, this.withdrawForm.amount - (this.topForm.totalCcBalance + this.topForm.lbtBalance), 2)
        );
      }
    },
    langTranslate(prefix, key) {
      const filteredKey = key ? key.replace(/[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/\s]/g, '').toLowerCase() : '';
      const comparedKey = prefix + filteredKey;
      const translatedLang = i18n.t(prefix + filteredKey);

      return comparedKey === translatedLang ? key : translatedLang;
    },
    getCountryIsoCode() {
      return countryIsoCode.find(c => c.id === parseInt(this.$store.state.common.countryCode)).code;
    },
    isSelectedCrypto() {
      return [34, 36, 80, 81, 86, 87, 106].includes(this.form.type);
    }
  },
  methods: {
    cpsSelect(payment) {
      this.$store.dispatch('cps/actionSetWithdrawalPayment', payment);
    },
    fetchWithdrawalMethod() {
      this.checkMethodMinAmount = this.$platform.getCheckMethodMinAmount(this.regulator);
      apiGetNonCreditCardWithdrawTypeCP({ accountNumber: this.withdrawForm.account.toString() })
        .then(resp => {
          if (resp.status === 200) {
            this.availableTypes = ['USD', 'USC'].includes(this.withdrawForm.currency)
              ? resp.data
              : resp.data.filter(type => ![4].includes(type.id));

            this.availableTypes = this.$platform.withdrawMethod.allowedCurrencies.hkBankTransfer.includes(
              this.withdrawForm.currency
            )
              ? this.availableTypes
              : this.availableTypes.filter(type => type.id != 49);

            this.checkRestrictedCountryByWithdrawType(2);
            //avoid showing other currency if API returns
            this.withdrawalTypes = this.availableTypes.filter(value =>
              value.availableCurrencies == null || value.availableCurrencies.length < 1
                ? true
                : value.availableCurrencies.includes(this.withdrawForm.currency)
            );

            // only USD account will be available for Mexico Bank Transfer withdrawal
            if ('USD' !== this.withdrawForm.currency)
              this.withdrawalTypes = this.withdrawalTypes.filter(type => ![63].includes(type.id));

            // get cps withdrawal type list
            this.fetchCpsWithdrawalMethod();
          } else {
            this.$message({ message: this.$t('withdraw.default.fetchTradeAccFailed'), type: 'error' });
          }
        })
        .catch(err => {
          this.$message({ message: this.$t('withdraw.default.fetchTradeAccFailed'), type: 'error' });
        });
    },
    fetchCpsWithdrawalMethod() {
      apiGetCpsWithdrawalChannel({ country: this.getCountryIsoCode })
        .then(resp => {
          if (resp.status === 200) {
            // combine channels into withdrawal type list
            resp.data.data.withdrawalType.channels.forEach(m => {
              let merchantVariable = JSON.parse(m.merchant_variable);
              let withdrawalList = {
                amountLimit: merchantVariable.amountLimit,
                availableCurrencies: null,
                category: 0,
                id: m.withdrawTypeId,
                cps: m,
                nameKey: merchantVariable.description_title
              };

              // restrict payment channel currencies
              this.restrictPaymentChannelCurrencies(withdrawalList);

              this.cpsPayments.push(m.withdrawTypeId);
            });

            // sort payment channel
            this.withdrawalTypes = this.sortPaymentChannel(this.withdrawalTypes);

            // Display Local Bank Transfer channels
            if (this.formType === 'localBankTransfer') {
              this.withdrawalTypes = this.withdrawalTypes.filter(type =>
                this.topForm.lbtTypesAndBalancesData.lbtType.includes(type.id)
              );
            }
          } else {
            this.$message({ message: this.$t('withdraw.default.fetchTradeAccFailed'), type: 'error' });
          }
        })
        .catch(err => {
          this.$message({ message: this.$t('withdraw.default.fetchTradeAccFailed'), type: 'error' });
        });
    },
    restrictPaymentChannelCurrencies(payment) {
      // check if payment channel is under restricted list
      if (CURRENCY_RESTRICTED_CHANNELS.includes(payment.id)) {
        if (
          CURRENCY_AVAILABLE_TYPES.hasOwnProperty(this.withdrawForm.currency) &&
          CURRENCY_AVAILABLE_TYPES[this.withdrawForm.currency].includes(payment.id)
        ) {
          if (MULTIPLE_CURRENCY_CHANNELS.includes(payment.id)) {
            if (payment.cps.currency_code == this.withdrawForm.currency) {
              this.withdrawalTypes.push(payment);
            }
            if (payment.cps.currency_code === 'USD' && this.withdrawForm.currency === 'USC') {
              this.withdrawalTypes.push(payment);
            }
          } else {
            this.withdrawalTypes.push(payment);
          }
        }
      } else {
        this.withdrawalTypes.push(payment);
      }
    },
    sortPaymentChannel(withdrawTypes) {
      let withdrawListOrder = [];
      let sortedWithdrawList = [];

      withdrawListingIndex.forEach(wi => {
        if (wi.id == this.countryCode) {
          withdrawListOrder = wi.order;
        }
      });

      if (withdrawListOrder.length == 0) {
        withdrawListingIndex.forEach(wi => {
          if (wi.id == 'global') {
            withdrawListOrder = wi.order;
          }
        });
      }

      withdrawListOrder.forEach(wi => {
        withdrawTypes.forEach(withdrawType => {
          if (wi == withdrawType.id) {
            sortedWithdrawList.push(withdrawType);
          }
        });
      });

      const remainingPayments = withdrawTypes.filter(v => !withdrawListOrder.includes(v.id));
      sortedWithdrawList = sortedWithdrawList.concat(remainingPayments);

      // IBT channel need to put in last order
      const ibtChannelIndex = sortedWithdrawList.findIndex(sw => sw.id === 2);
      const [ibtChannel] = sortedWithdrawList.splice(ibtChannelIndex, 1);
      sortedWithdrawList.push(ibtChannel);

      return sortedWithdrawList;
    },
    getFormOfChild() {
      // get form of child
      let form = null;
      if (this.form.type === 2) {
        form = this.$refs.child.$refs.bankChild ? this.$refs.child.$refs.bankChild.$refs['form'] : null;
      } else {
        form = this.$refs.child.$refs['form'];
      }
      return form;
    },
    getFormDataOfChild() {
      // get data of child for apply withdrawl API
      return this.form.type === 2 ? this.$refs.child.$refs.bankChild.getFormData() : this.$refs.child.getFormData();
    },
    submitForm() {
      return this.getFormOfChild() && (this.checkMethodMinAmount ? !this.belowMinimumAmount : true)
        ? this.getFormOfChild().validate()
        : Promise.reject();
    },
    getTitleLabel(val) {
      if (this.$te(val) || this.$te(val, 'en_US')) {
        return this.$t(val);
      }
      return this.$options.filters.withdrawMethods(val);
    },
    checkRestrictedCountryByWithdrawType(withdrawType) {
      this.showNote = !this.availableTypes.find(f => f.id === withdrawType) ? true : false;
    },
    handleOptionChange(selectedValue) {
      this.selectedWithdrawalMethod = selectedValue;
      this.freezeDialogVisible = true;
    },
    updateWithdrawalMethod(method) {
      this.form.type = method;
    },
    resetCom() {
      this.chosenTypeInfo = null;
      this.currentComponent = null;
      this.withdrawalBankList = [];
      this.checkMethodMinAmount = false;
      this.$emit('setType', null, false, 0);
    },
    resetForAuth() {
      this.form.type = null;
      this.resetCom();
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/components/withdraw/method.scss';
</style>
