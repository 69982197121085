<template>
  <div id="unionPayForm" class="bankForm_wrapper">
    <el-form label-position="top" :model="form" ref="form" :rules="rules">
      <el-row :gutter="20">
        <el-col :xs="24" :sm="8">
          <el-form-item :label="$t('common.field.selectWithdrawCard')" prop="bankCard">
            <el-select v-model="form.bankCard" data-testid="type">
              <el-option
                v-for="card in form.cards"
                :key="card.id"
                :value="card.id"
                :label="card.showNumber"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :xs="24" :sm="8">
          <el-form-item :label="$t('common.field.bankName')" prop="bankName">
            <el-input v-model="form.bankName" data-testid="bankName"></el-input>
          </el-form-item>
        </el-col>
        <el-col :xs="24" :sm="8">
          <el-form-item :label="$t('common.field.bankAccNum')" prop="accountNumber">
            <el-input v-model="form.accountNumber" data-testid="accountNumber"></el-input>
          </el-form-item>
        </el-col>
        <el-col :xs="24" :sm="8">
          <el-form-item :label="$t('common.field.bankBranch')" prop="bankBranchName">
            <el-input v-model="form.bankBranchName" data-testid="bankBranchName"></el-input>
          </el-form-item>
        </el-col>
        <el-col :xs="24" :sm="8">
          <el-form-item
            :label="$t('common.field.bankAccName')"
            prop="bankAccountName"
            :class="{ text_ru: lang === 'ru' }"
          >
            <el-input v-model="form.bankAccountName" data-testid="bankAccountName"></el-input>
          </el-form-item>
        </el-col>
        <el-col :xs="24" :sm="8">
          <el-form-item :label="$t('common.field.imptNotes')" prop="importantNotes">
            <el-input v-model="form.importantNotes" data-testid="importantNotes"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>

    <vDialog :show.sync="showUnionPayDialog">
      <div class="text-center">
        <div class="mb-3 mb-md-5">{{ unionPayDialog.text }}</div>

        <el-button class="el-button blue_button" @click="goToWithDrawDetail">
          {{ $t('common.button.confirm') }}
        </el-button>
      </div>
    </vDialog>
    <vDialog :show.sync="showTip">
      <div class="tip-content">
        <i class="el-icon-warning-outline warning_icon_blue"></i>
        <div class="unionpay-tip mt-3 mb-5" v-html="$t('deposit.unionPay.tip')"></div>
        <el-button class="el-button blue_button bg-primary" @click="showTip = false">
          {{ $t('common.button.confirm') }}
        </el-button>
      </div>
    </vDialog>
  </div>
</template>

<script>
import { apiCardInformation } from '@/resource';
import vDialog from '@/components/vDialog';

export default {
  name: 'unionpayWithdraw',
  props: { accountNumber: Number, amount: Number, withdrawalType: Number },
  components: { vDialog },
  data() {
    return {
      form: {
        bankName: '',
        accountNumber: '',
        bankBranchName: '',
        bankAccountName: '',
        importantNotes: '',
        bankCard: '',
        cards: []
      },
      unionPayDialog: {
        show: false,
        text: ''
      },
      rules: {
        bankCard: [
          {
            required: true,
            message: this.$t('common.formValidation.bankCardReq'),

            trigger: 'blur'
          }
        ],
        bankName: [
          {
            required: true,
            message: this.$t('common.formValidation.bankNameReq'),

            trigger: 'blur'
          }
        ],
        accountNumber: [
          {
            required: true,
            message: this.$t('common.formValidation.bankAccNumReq'),
            trigger: 'blur'
          }
        ],
        bankBranchName: [
          {
            required: true,
            message: this.$t('common.formValidation.bankBranchReq'),
            trigger: 'blur'
          }
        ],
        bankAccountName: [
          {
            required: true,
            message: this.$t('common.formValidation.accNameReq'),
            trigger: 'blur'
          }
        ]
      },
      showTip: true
    };
  },
  watch: {
    'form.bankCard'() {
      let currentCard = null;
      currentCard = this.form.cards.find(a => parseInt(a.id) == this.form.bankCard);
      this.form.bankName = currentCard.bank_name;
      this.form.bankCard = currentCard.card_number;
      this.form.bankBranchName = currentCard.branch_name;
      this.form.bankAccountName = currentCard.card_holder_name;
      this.form.accountNumber = this.form.bankCard;
    }
  },
  methods: {
    queryCards() {
      return apiCardInformation(0)
        .then(resp => {
          if (resp.data.code == 0) {
            return resp.data.data;
          } else {
            return [];
          }
        })
        .catch(err => {
          console.log(err);
        });
    },
    getFormData() {
      return {
        qAccount: this.accountNumber,
        amount: this.amount,
        withdrawalType: this.withdrawalType,
        bankName: this.form.bankName,
        accountNumber: this.form.bankCard,
        bankBranchName: this.form.bankBranchName,
        bankAccountName: this.form.bankAccountName,
        importantNotes: this.form.importantNotes
      };
    },
    goToWithDrawDetail() {
      this.$router.push('paymentDetails');
    }
  },
  computed: {
    lang() {
      return this.$store.state.common.lang;
    },
    showUnionPayDialog() {
      return this.unionPayDialog.show && !this.showTip;
    }
  },
  mounted() {
    this.queryCards().then(cards => {
      if (cards == null || cards.length == 0) {
        this.unionPayDialog = {
          show: true,
          text: this.$t('withdraw.default.noCardWarn')
        };
      } else {
        this.form.cards = cards;
        this.form.cards.forEach(card => {
          card.id = card.card_number;
          card.showNumber =
            card.card_number.substr(0, 6) + '******' + card.card_number.substr(card.card_number.length - 4);
        });
        this.form.bankName = cards[0].bank_name;
        this.form.bankBranchName = cards[0].branch_name;
        this.form.accountNumber = cards[0].showNumber;
        this.form.bankAccountName = cards[0].card_holder_name;
        this.form.bankCard = cards[0].card_number;
      }
    });
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/components/withdraw/banktransfer.scss';

.tip-content {
  text-align: center;
  .warning_icon_blue {
    font-size: 70px;
    color: $primary;
    margin-bottom: 12px;
  }
  .unionpay-tip {
    text-align: left;
  }
}
/deep/ .el-dialog__wrapper {
  .el-dialog__footer {
    padding-bottom: 0px;
  }
}
</style>
