const UNIONPAY = 4
const THAILANDBANKTRANSFER = 5
const MALAYSIABANKTRANSFER = 6
const FASAPAY = 7
const VIETNAMBANKTRANSFER = 8
const INDIABANKTRANSFER = 24
const SOUTHKOREABANKTRANSFER = 35
const INDONESIABANKTRANSFER = 39
const PHILIPPINESBANKTRANSFER = 40
const HONGKONGBANKTRANSFER = 49
const JAPANBANKTRANSFER = 62
const MEXICOBANKTRANSFER = 63
const BRAZILBANKTRANSFER = 64
const PAGSMILEPERUBANKTRANSFER = 73
const CHILEBANKTRANSFER = 88
const COLUMBIABANKTRANSFER = 89
const PERUBANKTRANSFER = 90
const FXIR = 97
const HONGKONGBANKUSDTRANSFER = 101
const PIX = 100

export const CURRENCY_RESTRICTED_CHANNELS = [
  UNIONPAY,
  THAILANDBANKTRANSFER,
  MALAYSIABANKTRANSFER,
  FASAPAY,
  VIETNAMBANKTRANSFER,
  INDIABANKTRANSFER,
  SOUTHKOREABANKTRANSFER,
  INDONESIABANKTRANSFER,
  PHILIPPINESBANKTRANSFER,
  HONGKONGBANKTRANSFER,
  MEXICOBANKTRANSFER,
  BRAZILBANKTRANSFER,
  PIX,
  PAGSMILEPERUBANKTRANSFER,
  PERUBANKTRANSFER,
  CHILEBANKTRANSFER,
  COLUMBIABANKTRANSFER,
  HONGKONGBANKUSDTRANSFER,
  JAPANBANKTRANSFER,
  FXIR,
]

export const MULTIPLE_CURRENCY_CHANNELS = []

export const CURRENCY_AVAILABLE_TYPES = {
  USD: [
    UNIONPAY,
    THAILANDBANKTRANSFER,
    MALAYSIABANKTRANSFER,
    FASAPAY,
    VIETNAMBANKTRANSFER,
    INDIABANKTRANSFER,
    SOUTHKOREABANKTRANSFER,
    INDONESIABANKTRANSFER,
    PHILIPPINESBANKTRANSFER,
    HONGKONGBANKTRANSFER,
    MEXICOBANKTRANSFER,
    BRAZILBANKTRANSFER,
    PIX,
    PAGSMILEPERUBANKTRANSFER,
    PERUBANKTRANSFER,
    CHILEBANKTRANSFER,
    COLUMBIABANKTRANSFER,
    HONGKONGBANKUSDTRANSFER,
    JAPANBANKTRANSFER,
    FXIR,
  ],
  USC: [
    UNIONPAY,
    THAILANDBANKTRANSFER,
    MALAYSIABANKTRANSFER,
    FASAPAY,
    VIETNAMBANKTRANSFER,
    INDIABANKTRANSFER,
    SOUTHKOREABANKTRANSFER,
    INDONESIABANKTRANSFER,
    PHILIPPINESBANKTRANSFER,
    HONGKONGBANKTRANSFER,
    MEXICOBANKTRANSFER,
    BRAZILBANKTRANSFER,
    PIX,
    PAGSMILEPERUBANKTRANSFER,
    PERUBANKTRANSFER,
    CHILEBANKTRANSFER,
    COLUMBIABANKTRANSFER,
    JAPANBANKTRANSFER,
    FXIR,
  ],
  HKD: [HONGKONGBANKTRANSFER],
  KRW: [SOUTHKOREABANKTRANSFER],
  MXN: [MEXICOBANKTRANSFER],
  BRL: [BRAZILBANKTRANSFER, PIX],
  JPY: [JAPANBANKTRANSFER],
}
