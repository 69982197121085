<template>
  <el-row>
    <el-col>
      <el-card class="mb-3">
        <el-form :model="withdrawForm" ref="withdrawForm" label-position="top" :rules="withdrawFormRules">
          <ul class="clearfix">
            <li class="fl">
              <el-form-item :label="$t('common.field.accNum')" prop="formAccount">
                <el-select v-model="withdrawForm.formAccount" data-testid="formAccount">
                  <el-option
                    v-for="item in formAccountOptions"
                    :key="item.login"
                    :value="JSON.stringify(item)"
                    :label="getCurrencySymbol(item)"
                    :data-testid="item.login"
                  ></el-option>
                </el-select>
              </el-form-item>
            </li>
            <li class="fr">
              <el-form-item :label="$t('common.field.amt')" prop="amount">
                <numeric-input
                  ref="numberInput"
                  class="transfer-amount"
                  v-model="withdrawForm.amount"
                  :currency="withdrawForm.currency"
                  :precision="2"
                ></numeric-input>
              </el-form-item>
            </li>
          </ul>
          <div class="btn_container">
            <el-button class="bg-primary border-primary" @click="submit" data-testid="submit" :disabled="disabled">
              <span class="me-3"> {{ $t('common.button.continue') }}</span>
              <i class="el-icon-arrow-right"></i>
            </el-button>
          </div>
        </el-form>
      </el-card>
    </el-col>
  </el-row>
</template>

<script>
import NumericInput from '@/components/NumericInput';
import splitThousands from '@/util/splitThousands';
import { apiGetWithdrawalData_cp, apiCreditCardBalances, apiGetLBTWithdrawalData_cp } from '@/resource';
import { decimalAdding } from '@/util/decimal';
import { countryIsoCode } from '@/constants/countryCode';

const defaultMinLimit = 40;

export default {
  name: 'top',
  components: { NumericInput },
  data() {
    return {
      creditCardBalances: [],
      lbtTypesAndBalancesData: [],
      formAccountOptions: [],
      countryBankList: [],
      userCountryCode: null,
      withdrawForm: {
        formAccount: '',
        account: '',
        amount: 0,
        currency: null,
        balance: null
      },
      checkMethodMinAmt: false,
      methodMinAmt: 0,
      disabled: false
    };
  },
  mounted() {
    this.fetchTradingAccount();
    this.$root.$refs.top = this;
  },
  computed: {
    minLimit() {
      return this.checkMethodMinAmt
        ? this.methodMinAmt
        : this.withdrawForm.balance <= defaultMinLimit
        ? this.withdrawForm.balance
        : defaultMinLimit;
    },
    withdrawFormRules() {
      return {
        formAccount: [
          {
            required: true,
            message: this.$t('common.formValidation.accReq'),
            trigger: 'change'
          }
        ],
        amount: [
          {
            required: true,
            message: this.$t('common.formValidation.amtReq'),
            trigger: 'blur'
          },
          {
            validator: (rule, value, callback) => {
              if (parseFloat(value) <= 0) {
                callback(new Error(this.$t('common.formValidation.amt0')));
              } else if (value < this.minLimit) {
                callback(
                  new Error(
                    this.$t('common.formValidation.withdrawAmtLarger', {
                      minLimit: this.minLimit,
                      currency: this.withdrawForm.currency
                    })
                  )
                );
              } else if (value > this.withdrawForm.balance) {
                callback(new Error(this.$t('withdraw.default.YourPaymentGreaterThanAvailableBalance')));
              } else callback();
            },
            trigger: 'blur'
          }
        ],
        withdrawalType: [
          {
            required: true,
            message: this.$t('common.formValidation.withdrawMethodReq'),
            trigger: 'change'
          }
        ]
      };
    },
    getCountryIsoCode() {
      return countryIsoCode.find(c => c.id === parseInt(this.$store.state.common.countryCode)).code;
    },
    showCC() {
      return this.creditCardBalances.length > 0;
    },
    showLBT() {
      return (
        this.lbtBalance > 0 &&
        this.lbtTypesAndBalancesData.isLbtLimited &&
        this.withdrawForm.amount > this.totalCcBalance
      );
    },
    showOtherPayment() {
      return this.withdrawForm.amount > decimalAdding(this.totalCcBalance, this.lbtBalance, 2);
    },
    totalCcBalance() {
      let total = 0;
      this.creditCardBalances.forEach(cc => {
        if (cc.creditCard.is_del !== 1 && !cc.isExpired && !cc.isPending) {
          total = decimalAdding(total, cc.balance, 2);
        }
      });
      return total;
    },
    lbtBalance() {
      const hasProperty = this.lbtTypesAndBalancesData.lbtBalance.hasOwnProperty(this.withdrawForm.currency);
      const isLimited = this.lbtTypesAndBalancesData.isLbtLimited;

      if (hasProperty && isLimited) {
        return this.lbtTypesAndBalancesData.lbtBalance[this.withdrawForm.currency];
      } else {
        return 0;
      }
    }
  },
  watch: {
    withdrawForm: {
      handler(form) {
        this.$emit('setForm', form);
        this.$emit('reset', true);
      },
      deep: true
    },
    'withdrawForm.formAccount'(value) {
      const jsonParse = JSON.parse(value);
      this.withdrawForm.account = jsonParse.login;
      this.withdrawForm.balance = jsonParse.balance;
      if (this.withdrawForm.currency === jsonParse.currency) {
        this.$refs.numberInput.updateCurrency(true);
      }
      this.withdrawForm.currency = jsonParse.currency;
      this.checkMethodMinAmt = false;
      this.methodMinAmt = 0;
      this.$refs['withdrawForm'].fields.find(f => f.prop == 'amount').resetField();

      this.disabled = false;
    },
    'withdrawForm.amount'(value) {
      this.checkMethodMinAmt = false;
      this.methodMinAmt = 0;
      this.disabled = false;
    },
    formAccountOptions(options) {
      // 預設第一筆
      if (options.length > 0) {
        this.withdrawForm.formAccount = JSON.stringify(options[0]);
      }
    }
  },
  methods: {
    fetchTradingAccount() {
      apiGetWithdrawalData_cp()
        .then(resp => {
          if (resp.data.code === 0) {
            this.userCountryCode = parseInt(resp.data.data.countryCode);
            this.formAccountOptions = resp.data.data.logins;
            this.countryBankList = new Map(
              resp.data.data.withdrawalType.map(witdrawType => {
                let withdrawBankList = witdrawType.withdrawBankList;
                let bankList = [];
                for (const key in withdrawBankList) {
                  if (Object.hasOwnProperty.call(withdrawBankList, key)) {
                    const bank = withdrawBankList[key];
                    bankList.push({ label: bank, value: bank });
                  }
                }
                bankList.sort((a, b) => a.label.localeCompare(b.label));
                return [witdrawType.id, bankList];
              })
            );
          } else {
            this.$message({ message: this.$t('withdraw.default.fetchTradeAccFailed'), type: 'error' });
          }
        })
        .catch(err => {
          this.$message({ message: this.$t('withdraw.default.fetchTradeAccFailed'), type: 'error' });
        });
    },
    submit() {
      this.$refs['withdrawForm'].validate(valid => {
        if (valid) {
          this.getApiBalances();
        } else return false;
      });
    },
    checkMinLimit(check, amount) {
      this.checkMethodMinAmt = check;
      this.methodMinAmt = amount;

      this.$refs['withdrawForm'].validate();
    },
    validateForm() {
      return this.$refs['withdrawForm'].validate();
    },
    getApiBalances() {
      Promise.all([
        apiCreditCardBalances({ currency: this.withdrawForm.currency }),
        apiGetLBTWithdrawalData_cp({ country: this.getCountryIsoCode }) // Get Type and Balances (Local Bank Transfer)
      ]).then(async res => {
        this.creditCardBalances = res[0].data;
        this.lbtTypesAndBalancesData = res[1].data.data;
        this.emitTopForm(this.creditCardBalances, this.lbtTypesAndBalancesData);
        this.$emit('reset', false);
      });
    },
    emitTopForm(creditCardBalances, lbtTypesAndBalancesData) {
      this.$emit(
        'setTopForm',
        {
          creditCardBalances: creditCardBalances,
          showCC: this.showCC,
          showOtherPayment: this.showOtherPayment,
          totalCcBalance: this.totalCcBalance,
          userCountryCode: this.userCountryCode,
          lbtTypesAndBalancesData: lbtTypesAndBalancesData, // [ lbtBalance:{}, lbtType:[] ]
          lbtBalance: this.lbtBalance, // local bank transfer current currency's balance
          showLBT: this.showLBT
        },
        this.countryBankList
      );
      this.disabled = this.showCC || this.showLBT || this.showOtherPayment;
    },
    getCurrencySymbol(item) {
      return (
        item.login +
        ' - ' +
        this.$options.filters.currencyToSymbol(item.currency) +
        splitThousands(item.balance, 2) +
        ' ' +
        item.currency
      );
    },
    resetWithdrawalData() {
      this.$emit('reset', true);
      this.getApiBalances();
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/components/withdraw/top.scss';
</style>
