export default {
  BankName: 'common.field.bankName',
  'Account Name': 'common.field.accName',
  'Account Number': 'common.field.accNum',
  accounttype: 'common.field.accType',
  accountdigit: 'common.field.accDigit',
  documenttype: 'common.field.documentType',
  'Document ID': 'common.field.docID',
  'Bank Branch': 'common.field.bankBranch',
  'Bank Account Currency': 'common.field.bankAccountCurrency',
  'Bank Account Name': 'common.field.bankAccName',
  'Bank Account Number': 'common.field.bankAccNum',
  'PIX Account Name': 'common.field.paymentAccName',
  'PIX Account Number': 'common.field.paymentAccNum',
  'Fasapay Account Name': 'common.field.paymentAccName',
  'Fasapay Account Number': 'common.field.paymentAccNum',
  'SWIFT Code': 'common.field.swift',
  BranchName: 'common.field.bankBranch',
  'Branch Code': 'common.field.bankBranchCode',
  Region: 'common.field.region',
  'FX-IR Account Name': 'common.field.paymentAccName',
  'FX-IR Wallet Number': 'common.field.walletNumber',
  EVP: 'withdraw.default.evp',
  PHONE: 'withdraw.default.phone',
}
