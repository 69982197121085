<template>
  <div id="astropayForm">
    <el-form label-position="top" :model="form" ref="form" :rules="rules">
      <el-row :gutter="40">
        <el-col :xs="24" :sm="12">
          <el-form-item :label="$t('withdraw.astropay.field.accountNumber')" prop="accountNumber">
            <el-input v-model="form.accountNumber" type="text" data-testid="accountNumber"></el-input>
          </el-form-item>
        </el-col>
        <el-col :xs="24" :sm="12">
          <el-form-item :label="$t('common.field.phone')" prop="phoneNumber">
            <el-input v-model="form.phoneNumber" type="text" data-testid="phoneNumber"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="40">
        <el-col :xs="24" :sm="12">
          <el-form-item :label="$t('common.field.imptNotes')" prop="importantNotes">
            <el-input v-model="form.importantNotes" data-testid="importantNotes"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
  </div>
</template>

<script>
import { validateNumber } from '@/util/validation';
export default {
  name: 'astropayWithdraw',
  props: { accountNumber: Number, amount: Number, withdrawalType: Number },
  data() {
    return {
      form: {
        accountNumber: '',
        phoneNumbe: '',
        importantNotes: ''
      },
      rules: {
        accountNumber: [
          {
            required: true,
            message: this.$t('withdraw.astropay.formValidation.accountNumberReq'),
            trigger: 'blur'
          }
        ],
        phoneNumber: [
          {
            required: true,
            message: this.$t('common.formValidation.dynamicReq', {
              dynamic: this.$t('common.field.phone')
            }),
            trigger: 'blur'
          },
          {
            validator: validateNumber,
            trigger: 'blur'
          }
        ]
      }
    };
  },
  methods: {
    getFormData() {
      return {
        qAccount: this.accountNumber,
        amount: this.amount,
        withdrawalType: this.withdrawalType,
        accountNumber: this.form.accountNumber,
        phoneNumber: this.form.phoneNumber,
        importantNotes: this.form.importantNotes
      };
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/components/withdraw/default.scss';
</style>
