<template>
  <!-- Transfer Int -->
  <div id="transferIntForm">
    <el-form label-position="top" :model="form" ref="form" :rules="rules">
      <el-row :gutter="30">
        <el-col :xs="24" :sm="8">
          <WithdrawlSharedOptions
            :label="chosenTypeInfo.subTypeNameKey"
            v-model="country"
            :list="chosenTypeInfo.withdrawSubTypes"
            :disabled="selectedCardID !== -1"
          ></WithdrawlSharedOptions>
        </el-col>
        <el-col :xs="24" :sm="8">
          <el-form-item :label="$t('common.field.bankName')" prop="bankName">
            <i class="el-icon-info label_icon text-primary"></i>
            <i18n path="common.hover.BankName" tag="hover"> </i18n>
            <el-input v-model="form.bankName" :disabled="isdisabled" data-testid="bankName"></el-input>
          </el-form-item>
        </el-col>
        <el-col :xs="24" :sm="8">
          <el-form-item :label="$t('common.field.bankAddress')" prop="bankAddress">
            <i class="el-icon-info label_icon text-primary"></i>
            <i18n path="common.hover.BankAddress" tag="hover"> </i18n>
            <el-input v-model="form.bankAddress" :disabled="isdisabled" data-testid="bankAddress"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="30">
        <el-col :xs="24" :sm="8">
          <el-form-item :label="$t('common.field.beneficiaryName')" prop="beneficiaryName">
            <i class="el-icon-info label_icon text-primary"></i>
            <i18n path="common.hover.BankBeneficiaryName" tag="hover"> </i18n>
            <el-input v-model="form.beneficiaryName" :disabled="isdisabled" data-testid="beneficiaryName"></el-input>
          </el-form-item>
        </el-col>
        <el-col :xs="24" :sm="8">
          <el-form-item
            :label="$t('common.field.bankAccOrIBAN')"
            prop="accountNumber"
            :class="{ text_long: ['ru', 'mn'].includes(lang) }"
          >
            <i class="el-icon-info label_icon text-primary"></i>
            <i18n path="common.hover.BankAccountNumber" tag="hover"> </i18n>
            <el-input v-model="form.accountNumber" :disabled="isdisabled" data-testid="accountNumber"></el-input>
          </el-form-item>
        </el-col>
        <el-col :xs="24" :sm="8">
          <el-form-item :label="$t('common.field.bankHolderAddress')" prop="holderAddress">
            <i class="el-icon-info label_icon text-primary"></i>
            <i18n path="common.hover.AccountHoldersAddress" tag="hover"> </i18n>
            <el-input v-model="form.holderAddress" :disabled="isdisabled" data-testid="holderAddress"></el-input>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row :gutter="30" v-if="userCountryCode == canCountryCode">
        <el-col :xs="24" :sm="8">
          <el-form-item label="Transit Number" prop="transitNumber">
            <el-input v-model="form.transitNumber" data-testid="transitNumber"></el-input>
          </el-form-item>
        </el-col>
        <el-col :xs="24" :sm="8">
          <el-form-item label="Institution Number" prop="institutionNumber">
            <el-input v-model="form.institutionNumber" data-testid="institutionNumber"></el-input>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row :gutter="30">
        <el-col :xs="24" :sm="8">
          <el-form-item :label="$t('common.field.swift')" prop="swift">
            <i class="el-icon-info label_icon text-primary"></i>
            <i18n path="common.hover.SwiftCode" tag="hover"> </i18n>
            <el-input v-model="form.swift" :disabled="isdisabled" data-testid="swift"></el-input>
          </el-form-item>
        </el-col>
        <el-col :xs="24" :sm="8">
          <el-form-item
            :label="$t('common.field.abaSortCode')"
            prop="sortCode"
            :class="{ text_long: ['ru'].includes(lang) }"
          >
            <el-input v-model="form.sortCode" :disabled="isdisabled" data-testid="sortCode"></el-input> </el-form-item
        ></el-col>
      </el-row>

      <el-row type="flex" class="row-bg" justify="space-between">
        <el-col :span="24">
          <el-form-item :label="$t('common.field.imptNotes')" prop="importantNotes">
            <el-input v-model="form.importantNotes" data-testid="importantNotes"></el-input>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row type="flex" class="row-bg" justify="space-between">
        <el-col :span="24">
          <el-form-item
            :label="$t(`withdraw.transfer.label.${isdisabled ? 'upload2' : 'upload'}`)"
            :class="{ upload: true, redText: isdisabled }"
            :prop="`${isdisabled ? '' : 'uploadedFile'}`"
          >
            <vUpload
              :limit="1"
              v-on:updateFileInfo="updateFileInfo"
              :accept="`image/png, image/jpeg, image/jpg, image/bmp, .pdf`"
              :allowedFile="['png', 'jpg', 'jpeg', 'bmp', 'pdf']"
              data-testid="uploadedFile"
            ></vUpload>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row type="flex" class="row-bg" justify="space-between">
        <el-col :span="24">
          <p
            class="int_p border border-primary text-primary mb-4"
            v-html="$t('withdraw.transfer.info', { platform: $platform.info.fullName })"
          ></p>
          <p v-if="regulator !== 'asic'" class="int_p border border-primary text-primary">
            {{ $t('withdraw.transfer.note', { email: GLOBAL_CONTACT_EMAIL }) }}
          </p>
        </el-col>
      </el-row>
      <div class="checkbox_wrapper" v-if="!form.userPaymentInfoId">
        <el-checkbox v-model="isRememberInfo" data-testid="checkbox">
          {{ $t('withdraw.default.remember') }}</el-checkbox
        >
      </div>
    </el-form>
  </div>
</template>

<script>
import bankTransferMixin from '@/mixins/withdrawl/bankTransfer';
import vUpload from '@/components/vUpload';
import Hover from '@/components/Hover';
import WithdrawlSharedOptions from '@/components/form/WithdrawlSharedOptions';

export default {
  name: 'transferIntWithdraw',
  props: [
    'accountNumber',
    'amount',
    'withdrawalType',
    'userCountryCode',
    'selectedCardInfo',
    'chosenTypeInfo',
    'transferCountryString',
    'selectedCardID'
  ],
  components: { vUpload, Hover, WithdrawlSharedOptions },
  mixins: [bankTransferMixin],
  data() {
    const validateFile = (rule, value, callback) => {
      if (this.fileList.length < 1) callback(new Error(this.$t('withdraw.transfer.formValidation.upload')));
      else callback();
    };
    return {
      country: null,
      form: {
        bankName: '',
        bankAddress: '',
        beneficiaryName: '',
        accountNumber: '',
        holderAddress: '',
        swift: '',
        sortCode: '',
        importantNotes: '',
        transitNumber: '',
        institutionNumber: '',
        userPaymentInfoId: null
      },
      rules: {
        bankName: [
          {
            required: true,
            message: this.$t('common.formValidation.bankNameReq'),

            trigger: 'blur'
          }
        ],
        bankAddress: [
          {
            required: true,
            message: this.$t('common.formValidation.bankAddressReq'),
            trigger: 'blur'
          }
        ],
        beneficiaryName: [
          {
            required: true,
            message: this.$t('common.formValidation.beneficiaryNameReq'),
            trigger: 'blur'
          }
        ],
        accountNumber: [
          {
            required: true,
            message: this.$t('common.formValidation.accNumIBANReq'),
            trigger: 'blur'
          }
        ],
        holderAddress: [
          {
            required: true,
            message: this.$t('common.formValidation.accHolderAddress'),
            trigger: 'blur'
          }
        ],
        swift: [
          {
            required: true,
            message: this.$t('common.formValidation.swiftReq'),
            trigger: 'blur'
          }
        ],
        uploadedFile: [
          {
            required: true,
            validator: validateFile,
            trigger: 'change'
          }
        ]
      },
      fileList: [],
      canCountryCode: 4575 //Canada
    };
  },
  watch: {
    selectedCardInfo: {
      immediate: true,
      handler(value) {
        console.log(value);
        if (value && value.id !== -1) {
          var notesObj = {};
          if (value.importantNotes) {
            var properties = value.importantNotes.split(', ');
            properties.forEach(function (property) {
              var tup = property.split(':');
              notesObj[tup[0]] = tup[1];
            });
            console.log(notesObj);
          }
          let notes = '';
          if (value.importantNotes) {
            if (this.userCountryCode === this.canCountryCode) {
              notes = notesObj['notes'];
            } else {
              notes = value.importantNotes;
            }
          }
          this.form = {
            bankName: value.bankName,
            bankAddress: value.bankAddress,
            accountNumber: value.accountNumber,
            beneficiaryName: value.beneficiaryName,
            holderAddress: value.holderAddress,
            sortCode: value.sortCode,
            swift: value.swift,
            importantNotes: notes,
            userPaymentInfoId: value.id,
            transitNumber: value.importantNotes ? notesObj['Transit Number'] : '',
            institutionNumber: value.importantNotes ? notesObj['Institution Number'] : ''
          };
          this.isdisabled = true;
        } else {
          this.isdisabled = false;
          this.form.userPaymentInfoId = null;
        }
      }
    },
    transferCountryString: {
      immediate: true,
      handler(val) {
        this.country = val;
      }
    },
    country(val) {
      this.$emit('update:transferCountryString', val);
    }
  },
  methods: {
    updateFileInfo(fileInfo) {
      this.fileList = fileInfo.fileList;
      this.$refs['form'].validateField('uploadedFile');
    },
    getFormData() {
      if (this.userCountryCode === this.canCountryCode) {
        this.form.importantNotes = `Transit Number: ${this.form.transitNumber}, Institution Number: ${this.form.institutionNumber}, notes: ${this.form.importantNotes}`;
      }
      return {
        qAccount: this.accountNumber,
        amount: this.amount,
        withdrawalType: this.withdrawalType,
        country: parseInt(this.country),
        bankName: this.form.bankName,
        bankAddress: this.form.bankAddress,
        accountNumber: this.form.accountNumber,
        beneficiaryName: this.form.beneficiaryName,
        holderAddress: this.form.holderAddress,
        sortCode: this.form.sortCode,
        swift: this.form.swift,
        importantNotes: this.form.importantNotes,
        fileList: this.fileList,
        isRememberInfo: this.form.userPaymentInfoId ? '' : this.isRememberInfo,
        userPaymentInfoId: this.form.userPaymentInfoId ? this.form.userPaymentInfoId : ''
      };
    }
  },
  computed: {
    lang() {
      return this.$store.state.common.lang;
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/components/withdraw/banktransfer.scss';
</style>
