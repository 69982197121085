<template>
  <el-form label-position="top" :model="form" ref="form" :rules="rules">
    <el-row :gutter="20">
      <el-col :xs="24" :sm="12">
        <el-form-item :label="$t('common.field.nameOnCard')" prop="card_holder_name">
          <el-input v-model="form.card_holder_name" :disabled="true" data-testid="card_holder_name"></el-input>
        </el-form-item>
      </el-col>
      <el-col :xs="24" :sm="12">
        <el-row :gutter="10">
          <el-form-item class="exp-date" :label="$t('common.field.expDate')" required>
            <el-col :span="12">
              <el-form-item prop="expiry_month">
                <Month
                  :year="form.expiry_year"
                  :disabled="disabledCardExpiry"
                  v-model="form.expiry_month"
                  @input="onCcDateChange()"
                ></Month>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item prop="expiry_year">
                <Year v-model="form.expiry_year" :disabled="disabledCardExpiry" @input="onCcDateChange()"></Year>
              </el-form-item>
            </el-col>
          </el-form-item>
        </el-row>
      </el-col>
      <el-col :span="24" v-if="isExpired && archivable">
        <span class="red">{{ $t('common.withdrawChannel.updateExpiryMsg') }}</span>
      </el-col>
      <el-col :span="24" v-show="ccDetail.availableCard">
        <el-form-item :label="$t('common.field.imptNotes')">
          <el-input v-model="form.importantNotes" data-testid="importantNotes"></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="24">
        <p class="red" v-if="ccDetail.availableCard">
          {{ $t('paymentDetails.cc.field.withrawlable', { amount: withdrawable }) }}
        </p>
      </el-col>
      <el-col :span="24" v-if="archivable">
        <span class="red">{{ $t('common.withdrawChannel.archiveThisCard') }}</span>
      </el-col>
    </el-row>
    <div class="archive-content">
      <div class="archive-action">
        <el-button
          v-if="(isExpired && archivable) || ccDetail.availableCard"
          @click="confirmPrompt('update')"
          :disabled="expCardUpdateDisabled"
        >
          {{ $t('common.withdrawChannel.update') }}
        </el-button>
        <el-button @click="showArchiveForm = true" v-if="!showArchiveForm && archivable">
          {{ $t('common.withdrawChannel.archive') }}
        </el-button>
      </div>
      <div class="mt-4" v-if="showArchiveForm">
        <div class="required-document">{{ $t('common.withdrawChannel.requiredDocument') }}</div>
        <div class="upload-tip">
          <span>{{ $t('fileUploader.supportedFormatCustom', { files: 'JPEG/JPG, PNG, PDF' }) }}, </span>
          <span>{{ $t('fileUploader.maxSize', { maxSize: '5' }) }}, </span>
          <span>{{ $t('fileUploader.maxFileNum', { maxFileNum: '1' }) }}</span>
        </div>
        <el-form :model="archiveForm" :rules="archiveFormRules" ref="archiveForm">
          <el-row :gutter="20">
            <el-col :xs="24" :sm="14">
              <span class="el-form-item__label">
                <span class="red">* </span>
                {{ $t('common.withdrawChannel.officialLetter') }}
              </span>
            </el-col>
            <el-col :xs="24" :sm="10">
              <el-form-item prop="cardBankLetterPath">
                <vUpload
                  :limit="uploadLimit"
                  :selfText="true"
                  :selfTip="true"
                  :accept="acceptableFiles"
                  :allowedFile="allowedFiles"
                  v-on:updateFileInfo="fileList => updateFileInfo(fileList, 'cardBankLetterPath')"
                >
                  <div class="upload-btn">
                    <el-button type="primary" data-testid="uploadBankLetter">
                      {{ $t('common.field.upload') }}
                    </el-button>
                  </div>
                </vUpload>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :xs="24" :sm="14">
              <span class="el-form-item__label">
                <span class="red">* </span>
                {{ $t('common.withdrawChannel.bankStatement') }}
              </span>
            </el-col>
            <el-col :xs="24" :sm="10">
              <el-form-item prop="cardStatementPath">
                <vUpload
                  :limit="uploadLimit"
                  :selfText="true"
                  :selfTip="true"
                  :accept="acceptableFiles"
                  :allowedFile="allowedFiles"
                  v-on:updateFileInfo="fileList => updateFileInfo(fileList, 'cardStatementPath')"
                >
                  <div class="upload-btn">
                    <el-button type="primary" data-testid="uploadBankStatement">
                      {{ $t('common.field.upload') }}
                    </el-button>
                  </div>
                </vUpload>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
        <div class="archive-action">
          <el-button plain @click="onArchiveCancel" data-testid="cancel">
            {{ $t('common.button.cancel') }}
          </el-button>
          <el-button @click="onArchiveSubmit" data-testid="archiveSubmit">
            {{ $t('common.button.submit') }}
          </el-button>
        </div>
      </div>
    </div>
  </el-form>
</template>

<script>
import Month from '@/components/form/Month';
import Year from '@/components/form/Year';
import vUpload from '@/components/vUpload';
import { cardAuditSubmit, apiUpdateCreditCard } from '@/resource';

export default {
  name: 'creditCardWithdraw',
  props: {
    ccDetail: Object,
    isExpired: {
      type: Boolean,
      default: false
    },
    archivable: {
      type: Boolean,
      default: false
    }
  },
  components: { Month, Year, vUpload },
  data() {
    const checkUploadFile = (rule, value, callback, name) => {
      if (!this.archiveForm[name]) {
        callback(new Error(this.$t('common.formValidation.file')));
      }
      callback();
    };
    const getUploadRules = name => {
      return [
        {
          validator: function(rule, value, callback) {
            checkUploadFile(rule, value, callback, name);
          },
          required: true,
          trigger: 'change'
        }
      ];
    };
    return {
      form: {
        card_holder_name: '',
        expiry_month: '',
        expiry_year: '',
        importantNotes: ''
      },
      otherCcDetail: {
        cardBeginSixDigits: '',
        cardLastFourDigits: ''
      },
      initialCcDate: {
        expiry_month: '',
        expiry_year: ''
      },
      rules: {
        card_holder_name: [
          {
            required: false,
            message: this.$t('common.formValidation.cardOnNameReq'),
            trigger: 'change'
          }
        ],
        expiry_month: [
          {
            required: true,
            message: this.$t('common.formValidation.expDateReq'),
            trigger: 'change'
          }
        ],
        expiry_year: [
          {
            required: true,
            message: this.$t('common.formValidation.expDateReq'),
            trigger: 'change'
          }
        ]
      },
      archiveForm: {
        cardBankLetterPath: '',
        cardStatementPath: ''
      },
      archiveFormRules: {
        cardBankLetterPath: getUploadRules('cardBankLetterPath'),
        cardStatementPath: getUploadRules('cardStatementPath')
      },
      withdrawable: '',
      showArchiveForm: false,
      uploadLimit: 1,
      expCardUpdateDisabled: true,
      acceptableFiles: 'image/jpeg,image/jpg,image/png,application/pdf',
      allowedFiles: ['pdf', 'png', 'jpg', 'jpeg']
    };
  },
  watch: {
    ccDetail: {
      handler() {
        this.setDetail();
      },
      immediate: true,
      deep: true
    }
  },
  computed: {
    disabledCardExpiry() {
      const { availableCard, creditCard, isExpired, isPending } = this.ccDetail;

      if (creditCard.is_del === 1) {
        return true;
      }

      if (isPending) {
        return true;
      }

      if (isExpired) {
        return false;
      }

      if (!availableCard) {
        return true;
      }

      return false;
    }
  },
  methods: {
    updateFileInfo(fileInfo, field) {
      this.archiveForm[field] = fileInfo.fileList[0];
      this.$refs['archiveForm'].validateField(field);
    },
    setDetail() {
      const creditCard = this.ccDetail.creditCard;

      this.initialCcDate.expiry_month = creditCard.expiry_month;
      this.initialCcDate.expiry_year = creditCard.expiry_year;

      this.form = {
        card_holder_name: creditCard.card_holder_name,
        expiry_month: creditCard.expiry_month,
        expiry_year: creditCard.expiry_year,
        importantNotes: ''
      };

      this.otherCcDetail = {
        cardBeginSixDigits: creditCard.card_begin_six_digits,
        cardLastFourDigits: creditCard.card_last_four_digits
      };

      this.withdrawable = this.ccDetail.balance;
    },
    getUpdatedCreditCardData() {
      const creditCardDetail = this.ccDetail.creditCard;
      return {
        id: creditCardDetail.id,
        card_holder_name: this.form.card_holder_name,
        card_begin_six_digits: creditCardDetail.card_begin_six_digits,
        card_last_four_digits: creditCardDetail.card_last_four_digits,
        expiry_month: parseInt(this.form.expiry_month),
        expiry_year: parseInt(this.form.expiry_year)
      };
    },
    getFormData() {
      return {
        currency: this.ccDetail.currency,
        creditCard: {
          ...this.getUpdatedCreditCardData()
        },
        importantNotes: this.form.importantNotes,
        withdrawAmount: this.ccDetail.showBalance
      };
    },
    onCcDateChange() {
      const isMonthChanged = parseInt(this.form.expiry_month) !== this.initialCcDate.expiry_month;
      const isYearChanged = parseInt(this.form.expiry_year) !== this.initialCcDate.expiry_year;
      if (isMonthChanged || isYearChanged) {
        this.expCardUpdateDisabled = false;
      } else {
        this.expCardUpdateDisabled = true;
      }
    },
    confirmPrompt(type) {
      this.$confirm(this.$t(`common.withdrawChannel.${type === 'archive' ? 'confirmArchive' : 'confirmUpdate'}`), '', {
        confirmButtonText: this.$t('common.button.confirm'),
        cancelButtonText: this.$t('common.button.cancel'),
        type: 'warning'
      })
        .then(() => {
          type === 'archive' ? this.archiveSubmit() : this.onExpiredCardUpdate();
        })
        .catch(() => {
          return;
        });
    },
    onArchiveSubmit() {
      this.$refs['archiveForm'].validate(valid => {
        if (valid) {
          this.confirmPrompt('archive');
        } else {
          return Promise.resolve(false);
        }
      });
    },
    archiveSubmit() {
      const data = {
        ...this.otherCcDetail,
        ...this.archiveForm,
        expiryMonth: this.form.expiry_month,
        expiryYear: this.form.expiry_year
      };
      cardAuditSubmit(data)
        .then(resp => {
          if (resp.data.code === 0) {
            this.responseMsg('success', this.$t('common.field.submitted'));
            this.resetUploadField();
            this.showArchiveForm = false;
            this.$root.$refs.top.resetWithdrawalData();
          }
        })
        .catch(err => {
          this.responseMsg('error', this.$t('responseMsg.500'));
        });
    },
    onExpiredCardUpdate() {
      const updatedCcData = { ...this.getUpdatedCreditCardData() };
      delete updatedCcData.card_holder_name;
      apiUpdateCreditCard(updatedCcData)
        .then(resp => {
          console.log('resp.data', resp.data);
          if (resp.data.code === 0 && resp.data) {
            this.responseMsg('success', this.$t('common.field.submitted'));
            this.$root.$refs.top.resetWithdrawalData();
          }
        })
        .catch(err => {
          console.log('err', err);
          this.responseMsg('error', this.$t('responseMsg.500'));
        });
    },
    responseMsg(type, msg) {
      this.$message({
        message: msg,
        type: type
      });
    },
    resetUploadField() {
      this.archiveForm = {
        cardBankLetterPath: '',
        cardStatementPath: ''
      };
    },
    onArchiveCancel() {
      this.showArchiveForm = false;
      this.resetUploadField();
    }
  }
};
</script>
