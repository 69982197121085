<template>
  <div class="tabs">
    <div class="d-flex" v-for="(item, index) in creditCardList" :key="index">
      <input
        type="checkbox"
        v-if="displayList(item.availableCard, item.isShow)"
        :id="tabIndex(index)"
        :data-testid="tabIndex(index)"
      />
      <div class="tab" v-if="displayList(item.availableCard, item.isShow)">
        <div class="tab-title">
          <el-row :gutter="10">
            <el-col :xs="24" :sm="9">
              <div class="label">Card{{ index + 1 }}</div>
              <el-select
                v-model="form[`form_${index}`]"
                :disabled="disabledSelect"
                :data-testid="`form_${index}`"
                @change="updateCcForm(index)"
              >
                <el-option
                  v-for="(list, i) in item.selectedCardList"
                  v-show="list.availableCard"
                  :key="i + index"
                  :value="i + index"
                  :label="setCreditCardNumber(list.creditCard)"
                  :data-testid="i + index"
                ></el-option>
              </el-select>
            </el-col>
            <el-col :xs="24" :sm="9">
              <div class="label">{{ $t('common.field.amt') }}</div>
              <div class="amount">{{ item.showBalance }}</div>
            </el-col>
            <el-col :xs="20" :sm="4" class="text-center">
              <div class="w-100" v-if="showAvailable">
                <span v-if="item.creditCard.is_del === 0">
                  {{ $t('common.withdrawChannel.available') }}
                </span>
              </div>
              <div class="w-100" v-else>
                <span>{{ $t(unvailableCardStatus(item)) }}</span>
              </div>
            </el-col>
            <el-col :xs="4" :sm="2" class="justify-content-center">
              <label :for="tabIndex(index)" :data-testid="`label${index}`"> <div class="arrow"></div> </label>
            </el-col>
          </el-row>
        </div>
        <div class="tab-content">
          <CreditCardWithdraw
            :ref="'creditCardWithdraw' + index"
            :ccDetail="item"
            :archivable="item.creditCard.is_del === 0 && !item.isPending"
            :isExpired="item.isExpired"
          ></CreditCardWithdraw>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CreditCardWithdraw from '@/components/withdraw/CreditCardWithdraw';

export default {
  name: 'ccTab',
  components: { CreditCardWithdraw },
  props: {
    creditCardList: {
      type: Array,
      default: () => []
    },
    form: {
      type: Object,
      default: () => {}
    },
    showAvailable: {
      type: Boolean,
      default: true
    },
    amount: Number,
    disableField: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    disabledSelect() {
      if (!this.showAvailable) {
        return true;
      }
      return this.disableField;
    }
  },
  methods: {
    updateCcForm(index) {
      this.$emit('updateCcForm', index);
    },
    setCreditCardNumber(creditCard) {
      return `${creditCard.card_begin_six_digits}** ****${creditCard.card_last_four_digits}`;
    },
    displayList(availableCard, isShow) {
      if (this.showAvailable) {
        return availableCard && isShow;
      }
      return !availableCard;
    },
    selectableCardList(list) {
      let cardList = list;
      if (this.showAvailable) {
        cardList = list.filter(card => card.availableCard);
      }
      return cardList;
    },
    unvailableCardStatus(item) {
      if (item.creditCard.is_del === 1) {
        return 'common.withdrawChannel.unavailable';
      }

      if (item.isPending) {
        return 'common.withdrawChannel.pending';
      }

      if (item.isExpired) {
        return 'home.accStatus.expired';
      }

      if (item.balance <= 0) {
        return 'common.withdrawChannel.fullyRefunded';
      }

      return '';
    },
    tabIndex(index) {
      return this.showAvailable ? `tab${index}` : `tab-unavailable-${index}`;
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/components/withdraw/ccMethod.scss';
</style>
