<template>
  <div id="transferCountry" class="bankForm_wrapper">
    <el-form label-position="top">
      <el-row :gutter="10">
        <el-col :xs="24" :sm="11" class="label_wrapper">
          <div class="label fl">{{ $t('common.withdrawChannel.banktransfer') }}</div>
          <el-form-item prop="selectedCardID" class="fr">
            <el-select v-model="form.selectedCardID" data-testid="selectedCardID">
              <el-option
                v-for="item in availableCards"
                :key="item.id"
                :value="item.id"
                :label="item.id | cardInfoDropdown(item, 2, dropdownTranslationObj)"
                :data-testid="item.id"
              ></el-option>
            </el-select> </el-form-item
        ></el-col>
        <el-col :xs="24" :sm="11" class="label_wrapper">
          <div class="label fl2 label_margin-left">{{ $t('common.field.amt') }}</div>
          <el-form-item prop="availableAmount" class="fr2">
            <el-input data-testid="availableAmount" v-model="amount" disabled></el-input> </el-form-item
        ></el-col>
      </el-row>
      <div v-if="form.selectedCardID">
        <div class="form_wrapper">
          <component
            :is="currentComponent"
            ref="bankChild"
            :accountNumber="accountNumber"
            :amount="amount"
            :currentCurrency="currentCurrency"
            :withdrawalType="withdrawalType"
            :userCountryCode="userCountryCode"
            :selectedCardInfo="selectedCardInfo"
            :transferCountryString.sync="transferCountryString"
            :chosenTypeInfo="chosenTypeInfo"
            :selectedCardID.sync="form.selectedCardID"
          ></component>
        </div>
      </div>
    </el-form>
  </div>
</template>

<script>
import TransferAUWithdraw from './TransferAUWithdraw';
import TransferIntWithdraw from './TransferIntWithdraw';
import WithdrawlSharedOptions from '@/components/form/WithdrawlSharedOptions';
import withdrawlMixin from '@/mixins/withdrawl/withdrawl';

export default {
  name: 'bankTransfer',
  components: { TransferAUWithdraw, TransferIntWithdraw, WithdrawlSharedOptions },
  props: ['amount', 'accountNumber', 'currentCurrency', 'withdrawalType', 'userCountryCode', 'chosenTypeInfo'],
  mixins: [withdrawlMixin],
  data() {
    return {
      currentComponent: null,
      transferCountry: null,
      selectedCardInfo: null,
      transferCountryString: null
    };
  },
  watch: {
    'form.selectedCardID'(value) {
      if (this.availableCards.length !== 0) {
        this.selectedCardInfo = this.availableCards.find(item => item.id === value);
        this.transferCountry = this.selectedCardInfo.country;
        this.transferCountryString = this.transferCountry
          ? this.transferCountry.toString()
          : this.chosenTypeInfo.withdrawSubTypes[0].code.toString();
      }
    },
    transferCountryString: {
      handler(val) {
        switch (val) {
          case '1':
            this.currentComponent = TransferAUWithdraw;
            break;
          case '2':
            this.currentComponent = TransferIntWithdraw;
            break;
          case '4':
            this.currentComponent = TransferAUWithdraw;
            break;
          default:
            this.currentComponent = null;
            break;
        }
      }
    }
  },
  methods: {
    submitWithdraw() {
      return this.$refs.bankChild.submitWithdraw();
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/pages/withdraw.scss';
@import '@/assets/css/components/withdraw/banktransfer.scss';
</style>
