<template>
  <div id="withdraw">
    <div class="content_box">
      <div class="inner_max">
        <component :is="greenIDVerificationComponent" v-if="greenIDStatus"></component>
        <idPoa v-else></idPoa>
        <AuthenticationNote />
        <div v-if="showWithdraw">
          <h3>{{ $t('menu.withdrawFunds') }}</h3>
          <div class="withDraw_box">
            <div class="withdraw_form" v-show="formFlag">
              <Top
                ref="top"
                :paymentAuthConfig="paymentAuthConfig"
                @setForm="getForm"
                @setTopForm="getTopForm"
                @reset="reset"
              ></Top>
              <el-card class="bottomForm" v-if="!resetForm">
                <CcMethod
                  ref="ccMethod"
                  :withdrawForm="withdrawForm"
                  :topForm="topForm"
                  v-if="topForm.showCC"
                ></CcMethod>
                <div class="line" v-if="topForm.showCC && (topForm.showLBT || topForm.showOtherPayment)"></div>

                <!-- formType 2.Bank Transfer 表單 -->
                <OtherMethod
                  ref="localBankTransferMethod"
                  :withdrawForm="withdrawForm"
                  :topForm="topForm"
                  :bankList="bankList"
                  v-if="topForm.showLBT"
                  @setType="setLBTType"
                  :title="$t('withdraw.default.localBankTransfer')"
                  :formType="'localBankTransfer'"
                ></OtherMethod>
                <div class="line" v-if="topForm.showLBT && topForm.showOtherPayment"></div>

                <OtherMethod
                  ref="otherMethod"
                  :withdrawForm="withdrawForm"
                  :topForm="topForm"
                  :bankList="bankList"
                  v-if="topForm.showOtherPayment"
                  @setType="setOtherType"
                  :title="
                    topForm.showCC || topForm.showLBT
                      ? $t('withdraw.otherMethod.otherType')
                      : $t('withdraw.otherMethod.withdrawalMethod')
                  "
                  :formType="'otherMethod'"
                ></OtherMethod>

                <el-row v-if="showSubmit">
                  <el-col class="text-center" :span="24">
                    <el-button
                      class="mt-4 bg-primary"
                      :loading="loading"
                      :disabled="loading"
                      @click="submitForm"
                      data-testid="submit"
                    >
                      {{ $t('common.button.submit') }}
                    </el-button>
                  </el-col>
                </el-row>
              </el-card>
              <div
                dir="ltr"
                class="disclaimer rounded text-white"
                v-html="$t('withdraw.default.disclaimer', { platform: $platform.info.fullName })"
              ></div>
            </div>
            <Result v-if="successFlag" :fail="result.fail">{{ result.message }}</Result>
            <vDialog :show.sync="dialog.show">
              <div class="text-center">
                <div class="mb-3 mb-md-5">{{ dialog.text }}</div>
                <el-button @click="dialog.show = false" data-testid="cancel">
                  {{ $t('common.button.cancel') }}
                </el-button>
              </div>
            </vDialog>
            <vDialog :show.sync="positionDialog.show">
              <div class="text-center">
                <div class="mb-3 mb-md-5">{{ positionDialog.text }}</div>
                <el-button
                  v-if="positionDialog.showConfirm"
                  @click="equityConfirm(submitWithdraw)"
                  data-testid="confirm"
                >
                  {{ $t('common.button.confirm') }}
                </el-button>
                <el-button plain v-if="positionDialog.showCancel" @click="hidePositionDialog" data-testid="cancel">
                  {{ $t('common.button.cancel') }}
                </el-button>
              </div>
            </vDialog>

            <!-- 当前修改账户名或者密码，T+x天内不支持出金 -->
            <el-dialog :visible.sync="visible" width="500px" @beforeClose="closeLimitDialog" center>
              <div class="title text-white">{{ $t('security.withdrawalFreeze.header') }}</div>
              <div class="description text-white">
                <template v-if="fromFunctionCode === 'updateUserLogin'">
                  {{ $t('security.withdrawalFreeze.userLoginDesc', { days: limitDayNumber, hours: lastHours }) }}
                </template>
                <template v-if="fromFunctionCode === 'updatePassword'">
                  {{ $t('security.withdrawalFreeze.passDesc', { days: limitDayNumber, hours: lastHours }) }}
                </template>
              </div>
              <span slot="footer" class="dialog-footer">
                <el-button type="primary" @click="closeLimitDialog" class="confirmBtn">{{
                  $t('security.understand')
                }}</el-button>
              </span>
            </el-dialog>
            <el-dialog
              :visible="confirmationVisibility"
              id="ConfirmationComponent"
              :close-on-click-modal="false"
              @close="cancelChanges"
            >
              <div class="dialog-body">
                <ConfirmationComponent
                  :showDynamicComponent="dynamicParameters"
                  @confirm="confirmChanges"
                  @changeVisible="cancelChanges"
                ></ConfirmationComponent>
              </div>
            </el-dialog>
            <VerificationDialog
              ref="verification"
              :dialogVisible.sync="verificationVisibility"
              :usedAuthTypes="paymentAuthConfig.authType"
              action="withdraw"
              @changeVisible="updateVerificationVisible"
              @confirm="confirmVerification"
            ></VerificationDialog>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Result from '@/components/Result';
import vDialog from '@/components/vDialog';
import idPoa from '@/components/idPoa/Index';
import Top from '@/components/withdraw/Top';
import CcMethod from '@/components/withdraw/CcMethod';
import OtherMethod from '@/components/withdraw/OtherMethod';
import CreditCardWithdraw from '@/components/withdraw/CreditCardWithdraw';
import mixin from '@/mixins';
import idPoaMixin from '@/mixins/idPoa';
import checkPositionMixin from '@/mixins/page/tools/checkPosition';
import greenIDMixin from '@/mixins/greenID';
import { apiApplyWithdrawal_cp_batch, apiGetMultiFactorAuth, apiGetSecurityInfo } from '@/resource';
import WithdrawalFreezeDialog from '@/components/withdraw/WithdrawalFreezeDialog.vue';
import AuthenticationNote from '@/components/security/AuthenticationNote';
import { mapVerifiedAuth, mapReverifiedBody } from '@/components/security/Security.js';
import ConfirmationComponent from '@/components/security/ConfirmationComponent.vue';
import VerificationDialog from '@/components/security/VerificationDialog.vue';

export default {
  name: 'WithdrawFunds',
  components: {
    Result,
    vDialog,
    idPoa,
    Top,
    CcMethod,
    OtherMethod,
    CreditCardWithdraw,
    WithdrawalFreezeDialog,
    AuthenticationNote,
    ConfirmationComponent,
    VerificationDialog
  },
  mixins: [mixin, idPoaMixin, checkPositionMixin, greenIDMixin],
  data() {
    return {
      formFlag: true,
      successFlag: false,
      result: { message: this.$t('withdraw.default.success'), fail: false },
      dialog: { show: false, text: '' },
      withdrawForm: {
        formAccount: '',
        account: '',
        amount: 0,
        currency: null,
        balance: null
      },
      topForm: {
        creditCardBalances: [],
        showCC: false,
        showOtherPayment: false,
        totalCcBalance: 0,
        userCountryCode: '',
        lbtTypesAndBalancesData: [],
        lbtBalance: 0,
        showLBT: false
      },
      bankList: {},
      resetForm: true,
      otherType: null,
      lbtType: null,
      freezeDialogVisible: false,
      visible: false,
      fromFunctionCode: '',
      limitDayNumber: '',
      lastHours: '',
      paymentAuthConfig: {},
      dynamicParameters: {},
      confirmationVisibility: false,
      verificationVisibility: false,
      apiRequest: [],
      loading: false
    };
  },
  computed: {
    showWithdraw() {
      // if id, poa pass or green id pass.
      return (
        (!this.requireIDProof &&
          !this.requireAddressProof &&
          !this.pendingIDProofApproval &&
          !this.pendingAddressProofApproval) ||
        (this.greenIDStatus && !this.pendingGreenIDStatus)
      );
    },
    isCryptoIBTPayments() {
      const paymentChannels = [
        5, 6, 8, 9, 24, 39, 40, 42, 47, 50, 51, 52, 53, 54, 55, 56, 57, 58, 63, 64, 34, 36, 80, 81
      ];
      const currentPaymentId = this.otherType;
      return currentPaymentId ? paymentChannels.includes(currentPaymentId) : false;
    },
    showSubmit() {
      const displayLBT = this.topForm.showLBT;
      const displayOtherPayment = this.topForm.showOtherPayment;
      const LBT_Pass = this.lbtType ? true : false;
      const OtherPayment_Pass = this.otherType ? true : false;

      if (displayLBT && !displayOtherPayment) {
        return LBT_Pass;
      } else if (!displayLBT && displayOtherPayment) {
        return OtherPayment_Pass;
      } else if (displayLBT && displayOtherPayment) {
        return LBT_Pass && OtherPayment_Pass;
      } else {
        return true;
      }
    }
  },
  watch: {},
  async mounted() {
    this.$store.commit('common/setAuthHeaders', {
      etxId: '',
      eCode: '',
      ptxId: '',
      pCode: '',
      tCode: ''
    });
    await this.getAuthInfo();
    await this.getPaymentAuthConfig();
  },
  watch: {
    'topForm.showCC'() {
      this.$store.commit('common/setAuthHeaders', {
        etxId: '',
        eCode: '',
        ptxId: '',
        pCode: '',
        tCode: ''
      });
      if (this.topForm.showCC) {
        //ensure totp is verified
        if (
          this.paymentAuthConfig.paymentChannel.includes(1) &&
          !this.paymentAuthConfig.authType.every(item => item.verified) &&
          !this.topForm.showOtherPayment
        ) {
          this.dynamicParameters = {
            title: this.$t('security.verificationTitle'),
            desc: this.$t('security.verificationDesc'),
            cancelButton: null,
            confirmButton: this.$t('common.button.confirm')
          };
          this.confirmationVisibility = true;
        }
      }
    },
    otherType() {
      console.log('otherType', this.otherType);
      if (
        this.paymentAuthConfig.paymentChannel.includes(this.otherType) &&
        !this.paymentAuthConfig.authType.every(item => item.verified)
      ) {
        this.dynamicParameters = {
          title: this.$t('security.verificationTitle'),
          desc: this.$t('security.verificationDesc'),
          cancelButton: this.$t('common.button.cancel'),
          confirmButton: this.$t('common.button.confirm')
        };
        this.confirmationVisibility = true;
      }
    }
  },
  methods: {
    confirmChanges() {
      this.$router.push('/securityManagement');
    },
    cancelChanges() {
      this.confirmationVisibility = false;
      this.$refs.otherMethod.resetForAuth();
    },
    updateVerificationVisible() {
      this.verificationVisibility = false;
    },
    async getAuthInfo() {
      await apiGetSecurityInfo().then(resp => {
        if (resp.data.code == 0) {
          const usedValue = resp.data.data;
          this.$store.commit('common/setCheckedAuthStatus', usedValue.authStatus);
        }
      });
    },
    async getPaymentAuthConfig() {
      await apiGetMultiFactorAuth('withdraw').then(resp => {
        if (resp.data.code == 0) {
          let usedRequiredAuths = this.$store.state.common.requiredAuths || {};
          usedRequiredAuths['withdraw'] = resp.data.data.authType;
          this.$store.commit('common/setRequiredAuths', usedRequiredAuths);
          this.paymentAuthConfig = {
            authType:
              resp.data.data.authType && resp.data.data.authType.length > 0
                ? resp.data.data.authType.map(item => {
                    return {
                      type: item,
                      verified: mapVerifiedAuth(item, this.$store.state.common.checkedAuthStatus),
                      authenticated: false
                    };
                  })
                : [],
            paymentChannel: resp.data.data.paymentChannel ? resp.data.data.paymentChannel : []
          };
        }
      });
    },
    getForm(form) {
      this.withdrawForm = { ...form };
    },
    getTopForm(topForm, countryBankList) {
      this.otherType = null;
      this.lbtType = null; // local bank transfer
      this.topForm = { ...topForm };
      this.bankList = countryBankList;
    },
    setOtherType(type, checkMethodMinAmount, methodMinAmount) {
      this.otherType = type;
      this.$refs.top.checkMinLimit(checkMethodMinAmount, methodMinAmount);
    },
    setLBTType(type, checkMethodMinAmount, methodMinAmount) {
      this.lbtType = type;
      this.$refs.top.checkMinLimit(checkMethodMinAmount, methodMinAmount);
    },
    reset(bool) {
      this.resetForm = bool;
    },
    getMethodComponent() {
      return [this.$refs.otherMethod, this.$refs.localBankTransferMethod, this.$refs.ccMethod, this.$refs.top];
    },
    validComponent(otherMethodComponent, lbtMethodComponent, ccMethodComponent, topComponent) {
      return [
        otherMethodComponent ? otherMethodComponent.submitForm() : Promise.resolve(),
        lbtMethodComponent ? lbtMethodComponent.submitForm() : Promise.resolve(),
        ccMethodComponent ? ccMethodComponent.submitForm() : Promise.resolve(),
        topComponent ? topComponent.validateForm() : Promise.resolve()
      ];
    },
    submitForm() {
      const [getOtherMethodComponent, getLbtMethodComponent, getCcMethodComponent, getTopComponent] =
        this.getMethodComponent();

      const [verifyOtherMethod, verifyLbtMethod, verifyCcMethod, verifyTopMethod] = this.validComponent(
        getOtherMethodComponent,
        getLbtMethodComponent,
        getCcMethodComponent,
        getTopComponent
      );

      let vaildParameter = getCcMethodComponent
        ? [verifyOtherMethod, verifyLbtMethod, ...verifyCcMethod, verifyTopMethod]
        : [verifyOtherMethod, verifyLbtMethod, verifyCcMethod, verifyTopMethod];

      Promise.all(vaildParameter).then(valid => {
        if (valid) {
          this.checkPosition(this.withdrawForm.account, this.withdrawForm.amount, 'withdraw', this.submitWithdraw);
        }
      });
    },
    confirmVerification() {
      this.verificationVisibility = false;
      this.submitApi();
    },
    postApiApplyWithdrawal_cp() {
      const [getOtherMethodComponent, getLbtMethodComponent, getCcMethodComponent] = this.getMethodComponent();

      let apiData = [];
      if (this.topForm.showCC) {
        const topFromData = { ...getCcMethodComponent.getFormDataOfChild() };
        if (topFromData.creditCardWithdrawalRequests.length > 0) {
          apiData.push({ ...getCcMethodComponent.getFormDataOfChild() });
        }
      }
      // Local bank transfer
      if (this.topForm.showLBT) apiData.push({ ...getLbtMethodComponent.getFormDataOfChild() });

      if (this.topForm.showOtherPayment) apiData.push({ ...getOtherMethodComponent.getFormDataOfChild() });
      return apiData;
    },
    submitWithdraw() {
      //get request
      this.apiRequest = this.postApiApplyWithdrawal_cp();
      // do authentication first
      const needAuth = this.apiRequest.some(item =>
        this.paymentAuthConfig.paymentChannel.includes(item.withdrawalType)
      );

      if (needAuth && this.paymentAuthConfig.authType.length > 0) {
        this.verificationVisibility = true;
      } else {
        this.submitApi();
      }
    },
    submitApi() {
      this.loading = true;
      apiApplyWithdrawal_cp_batch(this.apiRequest, this.token)
        .then(async result => {
          const limitCodes = [637, 638, 639];
          if (limitCodes.includes(result.data.code)) {
            await this.fetchToken();
            this.loading = false;
            // 638为修改账号引发的限制   639为修改密码引发的限制 637为修改2fa验证器引发的限制
            this.fromFunctionCode =
              result.data.code === 638
                ? 'updateUserLogin'
                : result.data.code === 637
                ? 'changeAuthenticator'
                : 'updatePassword';
            this.limitDayNumber = result.data.data.limitDayNumber;
            this.lastHours = result.data.data.lastHours;
            this.visible = true;
            await this.getPaymentAuthConfig();
          } else if (result && (result.data.code == 0 || (result.data.code >= 300 && result.data.code < 400))) {
            this.loading = false;
            this.formFlag = false;
            this.successFlag = true;
            this.handleErrorCode(result.data.code);
          } else if (result.data.code === 1201 || result.data.code == 1202) {
            await this.fetchToken();
            this.loading = false;
            await this.getPaymentAuthConfig();
            this.paymentAuthConfig.authType = mapReverifiedBody('withdraw', result);
            const allVerified = this.paymentAuthConfig.authType.every(item => item.verified == true);
            if (!allVerified) {
              if (
                this.paymentAuthConfig.paymentChannel.includes(1) &&
                this.apiRequest.filter(item => item.withdrawalType == 1).length > 0
              ) {
                this.dynamicParameters = {
                  title: this.$t('security.verificationTitle'),
                  desc: this.$t('security.verificationDesc'),
                  cancelButton: null,
                  confirmButton: this.$t('common.button.confirm')
                };
              } else {
                this.dynamicParameters = {
                  title: this.$t('security.verificationTitle'),
                  desc: this.$t('security.verificationDesc'),
                  cancelButton: this.$t('common.button.cancel'),
                  confirmButton: this.$t('common.button.confirm')
                };
              }
              this.confirmationVisibility = true;
            } else {
              this.verificationVisibility = true;
            }
          } else {
            this.$message({ message: this.$t('withdraw.default.failed'), type: 'error' });
            this.errorMessage(this.$t('withdraw.default.failed'));
            // re-calling anti-reuse token
            await this.fetchToken()
              .then(resp => {
                this.loading = false;
              })
              .catch(resp => {
                this.loading = false;
                this.$message({ message: this.$t('resetPassword.failed'), type: 'error' });
              });
          }
        })
        .catch(err => {
          this.loading = false;
          this.$message({ message: this.$t('withdraw.default.failed'), type: 'error' });
        });
    },
    async closeLimitDialog() {
      this.visible = false;
      await this.fetchToken();
    },
    handleErrorCode(code) {
      if (code === 300) {
        // for credit card
        this.result.fail = true;
        this.result.message = this.$t('withdraw.default.ccFail', {
          email: this.GLOBAL_CONTACT_EMAIL
        });
      } else if (code === 301) {
        this.successFlag = false;
        this.formFlag = true;
        this.result.fail = false;
        this.dialog = { show: true, text: this.$t('withdraw.default.notEnoughFunds') };
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/pages/withdraw.scss';

/deep/ .box-card-verification {
  margin-top: 16px;
  .el-form-item__label {
    float: none !important;
    padding-bottom: 10px;
    margin-bottom: 10px;
    display: inline-block;
  }
}

/deep/ .verification-code-container {
  justify-content: start !important;
  .verification-code-item + .verification-code-item {
    margin-left: 36px;
  }
  .verification-code-item {
    .el-button {
      height: 40px;
      padding: 8px 16px;
    }
  }
}

#ConfirmationComponent {
  /deep/ .el-dialog {
    margin-top: 15vh !important;
    border-radius: 16px;
    padding: 40px;
    height: auto;
    max-width: 480px;
    background-color: #161826;
    .el-dialog__body {
      text-align: center;
    }
    .el-dialog__headerbtn {
      display: none;
    }
  }
  /deep/ .el-dialog__header {
    padding: 0px;
  }
  /deep/ .el-dialog__body {
    font-size: 14px;
    font-weight: 400;
    padding: 0px;
    padding-bottom: 0px;
    .el-dialog__body {
      text-align: start;
    }
    .title {
      padding-bottom: 20px;
      color: #ffffff;
    }
    .body {
      padding-bottom: 20px;
    }
  }
  /deep/ .el-dialog__footer {
    padding: 0px;
  }
}

.title {
  font-size: 18px;
  font-weight: 700;
  text-align: center;
  margin-top: 10px;
  margin-bottom: 24px;
  line-height: 160%;
}
.description {
  font-size: 14px;
  font-weight: 500;
  text-align: center;
  margin: 0 30px;
  line-height: 160%;
}
/deep/ .el-dialog__footer {
  padding-bottom: 60px;
}
.confirmBtn {
  width: calc(100% - 70px);
  margin: 0 35px;
}
</style>
