<template>
  <!-- Transfer Aus/UK -->
  <div id="transferAUWithdraw">
    <el-form label-position="top" :model="form" ref="form" :rules="rules">
      <div>
        <el-row :gutter="30">
          <el-col :xs="24" :sm="8">
            <WithdrawlSharedOptions
              :label="chosenTypeInfo.subTypeNameKey"
              v-model="country"
              :list="chosenTypeInfo.withdrawSubTypes"
              :disabled="selectedCardID !== -1"
            ></WithdrawlSharedOptions>
          </el-col>
          <el-col :xs="24" :sm="8">
            <el-form-item :label="$t('common.field.bankName')" prop="bankName">
              <i class="el-icon-info label_icon text-primary"></i>
              <i18n path="common.hover.BankName" tag="hover"> </i18n>
              <el-input v-model="form.bankName" :disabled="isdisabled" data-testid="bankName"></el-input>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="8">
            <el-form-item :label="$t('common.field.bsb')" prop="bsbCode">
              <el-input v-model="form.bsbCode" :disabled="isdisabled" data-testid="bsbCode"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="30" :class="{ row_ru: lang === 'ru' }">
          <el-col :xs="24" :sm="8">
            <el-form-item
              :label="$t('common.field.beneficiaryName')"
              prop="beneficiaryName"
              :class="{ text_long: ['fr_FR'].includes(lang) }"
            >
              <i class="el-icon-info label_icon text-primary"></i>
              <i18n path="common.hover.BankBeneficiaryName" tag="hover"> </i18n>
              <el-input
                v-model="form.beneficiaryName"
                :disabled="isdisabled"
                data-testid="beneficiaryName"
              ></el-input> </el-form-item
          ></el-col>
          <div v-if="this.transferCountryString === '1'">
            <el-col :xs="24" :sm="8">
              <el-form-item
                :label="$t('common.field.swiftNonAUD')"
                prop="swift"
                :class="{ text_long: ['en_US', 'fr_FR', 'ja', 'th', 'ru', 'mn'].includes(lang) }"
              >
                <i class="el-icon-info label_icon text-primary"></i>
                <i18n path="common.hover.SwiftCode" tag="hover"> </i18n>
                <el-input v-model="form.swift" :disabled="isdisabled" data-testid="swift"></el-input>
              </el-form-item>
            </el-col>
          </div>
          <el-col :xs="24" :sm="8">
            <el-form-item :label="$t('common.field.bankAccNum')" prop="accountNumber">
              <i class="el-icon-info label_icon text-primary"></i>
              <i18n path="common.hover.BankAccountNumber" tag="hover"> </i18n>
              <el-input v-model="form.accountNumber" :disabled="isdisabled" data-testid="accountNumber"></el-input>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row type="flex" class="row-bg" justify="space-between" :gutter="30">
          <el-col :span="24">
            <el-form-item :label="$t('common.field.imptNotes')" prop="importantNotes">
              <el-input v-model="form.importantNotes" data-testid="importantNotes"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row type="flex" class="row-bg" justify="space-between">
          <el-col :span="24">
            <el-form-item
              :label="$t(`withdraw.transfer.label.${isdisabled ? 'upload2' : 'upload'}`)"
              :class="{ upload: true, redText: isdisabled }"
              :prop="`${isdisabled ? '' : 'uploadedFile'}`"
            >
              <vUpload
                :limit="1"
                v-on:updateFileInfo="updateFileInfo"
                :accept="`image/png, image/jpeg, image/jpg, image/bmp, .pdf`"
                :allowedFile="['png', 'jpg', 'jpeg', 'bmp', 'pdf']"
                data-testid="uploadedFile"
              ></vUpload>
            </el-form-item>
          </el-col>
        </el-row>
        <p v-if="regulator !== 'asic'" class="int_p border border-primary text-primary">
          {{ $t('withdraw.transfer.note', { email: GLOBAL_CONTACT_EMAIL }) }}
        </p>
      </div>
      <div class="checkbox_wrapper" v-if="!form.userPaymentInfoId">
        <el-checkbox v-model="isRememberInfo" data-testid="checkbox">
          {{ $t('withdraw.default.remember') }}</el-checkbox
        >
      </div>
    </el-form>
  </div>
</template>

<script>
import bankTransferMixin from '@/mixins/withdrawl/bankTransfer';
import vUpload from '@/components/vUpload';
import Hover from '@/components/Hover';
import WithdrawlSharedOptions from '@/components/form/WithdrawlSharedOptions';

export default {
  name: 'transferAUWithdraw',
  props: [
    'accountNumber',
    'amount',
    'currentCurrency',
    'withdrawalType',
    'userCountryCode',
    'selectedCardInfo',
    'chosenTypeInfo',
    'transferCountryString',
    'selectedCardID'
  ],
  components: { vUpload, Hover, WithdrawlSharedOptions },
  mixins: [bankTransferMixin],
  data() {
    const validateFile = (rule, value, callback) => {
      if (this.fileList.length < 1) callback(new Error(this.$t('withdraw.transfer.formValidation.upload')));
      else callback();
    };
    return {
      country: null,
      form: {
        bankName: '',
        bsbCode: '',
        beneficiaryName: '',
        swift: '',
        accountNumber: '',
        importantNotes: '',
        userPaymentInfoId: null
      },
      rules: {
        bankName: [
          {
            required: true,
            message: this.$t('common.formValidation.bankNameReq'),
            trigger: 'blur'
          }
        ],
        bsbCode: [
          {
            required: true,
            message: this.$t('common.formValidation.bsbReq'),
            trigger: 'blur'
          }
        ],
        beneficiaryName: [
          {
            required: true,
            message: this.$t('common.formValidation.beneficiaryNameReq'),
            trigger: 'blur'
          }
        ],
        accountNumber: [
          {
            required: true,
            message: this.$t('common.formValidation.bankAccNumReq'),
            trigger: 'blur'
          }
        ],
        uploadedFile: [
          {
            required: true,
            validator: validateFile,
            trigger: 'change'
          }
        ],
        swift: []
      },
      fileList: []
    };
  },
  methods: {
    updateFileInfo(fileInfo) {
      this.fileList = fileInfo.fileList;
      this.$refs['form'].validateField('uploadedFile');
    },
    getFormData() {
      return {
        qAccount: this.accountNumber,
        amount: this.amount,
        withdrawalType: this.withdrawalType,
        country: parseInt(this.country),
        bankName: this.form.bankName,
        bsbCode: this.form.bsbCode,
        beneficiaryName: this.form.beneficiaryName,
        swift: this.transferCountryString === '1' ? this.form.swift : '',
        accountNumber: this.form.accountNumber,
        importantNotes: this.form.importantNotes,
        fileList: this.fileList,
        isRememberInfo: this.form.userPaymentInfoId ? '' : this.isRememberInfo,
        userPaymentInfoId: this.form.userPaymentInfoId ? this.form.userPaymentInfoId : ''
      };
    }
  },
  watch: {
    selectedCardInfo: {
      immediate: true,
      handler(value) {
        const isCurrentCard = value && value.id !== -1;

        this.form = {
          bankName: value.bankName || '',
          accountNumber: value.accountNumber || '',
          beneficiaryName: value.beneficiaryName || '',
          bsbCode: value.bsbCode || '',
          swift: value.swift || '',
          importantNotes: value.importantNotes || '',
          userPaymentInfoId: isCurrentCard ? value.id : null
        };
        this.isdisabled = isCurrentCard ? true : false;
      }
    },
    transferCountryString: {
      immediate: true,
      handler(val) {
        this.country = val;
      }
    },
    country(val) {
      this.$emit('update:transferCountryString', val);
    }
  },
  computed: {
    lang() {
      return this.$store.state.common.lang;
    }
  },
  mounted() {
    if (this.currentCurrency !== 'AUD') {
      this.rules.swift = [
        {
          required: true,
          message: this.$t('common.formValidation.swiftReq'),
          trigger: 'blur'
        }
      ];
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/components/withdraw/banktransfer.scss';
</style>
